@import "../../../styles/theme.scss";
.status-tag-col {
  position: absolute;
  width: auto;
  right: 15px;
  border-radius: 30px;
  background: rgba(82, 247, 177, 0.1);
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 10px;
  color: #52f7b1;
  text-align: center;
  font-family: Wix Madefor Display sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.status-tag-col-pending {
  color: #1079E8;
  background: rgba(16, 121, 232, 0.10);
  position: absolute;
  width: auto;
  right: 15px;
  border-radius: 30px;
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 10px;
  text-align: center;
  font-family: Wix Madefor Display sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.status-tag-col-canceled {
  position: absolute;
  width: auto;
  right: 15px;
  border-radius: 30px;
  background: rgba(255 79 60 / 10%);
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 10px;
  color: red;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.status-tag-col-ongoing {
  position: absolute;
  width: auto;
  right: 15px;
  border-radius: 30px;
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(135deg, #02e09e 0%, #66b6fa 100%);
}

// appointment dashboard styles
.appointment-dashboard {
  .appointment-tab {
    .ant-tabs-nav {
      margin-bottom: 25px;

      &:before {
        display: none;
      }
    }

    .ant-tabs-ink-bar {
      background-color: transparent;
    }

    .ant-tabs-tab {
      background: $light-blue;
      width: 125px;
      height: 50px;
      border-radius: 70px;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-tabs-tab-btn {
        font-size: 16px;
        color: $white;
        font-weight: 600;
      }

      &.ant-tabs-tab-active,
      &:hover {
        background: $theme-gradient;

        .ant-tabs-tab-btn {
          color: $white;
        }
      }
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 15px;
    }

    .appointments-listing {
      height: calc(100vh - 245px);
      overflow: auto;

      .appointment-wrapper {
        background: $white;
        padding: 20px;
        border-radius: 15px;
        margin-bottom: 15px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
        transition: 0.3s ease all;
        position: relative;
        cursor: pointer;
        border: 2px solid transparent;

        .person-name {
          font-weight: 700;
          font-size: 20px;
        }

        .service-fee {
          span {
            font-size: 14px;
          }

          border-radius: 8px;
          text-align: center;
          padding: 10px 10px;
          color: #ffffff;
          width: fit-content;
          background: #112047;
          font-size: 16px;
          margin-bottom: 20px;
          justify-content: center;
          display: flex;
        }

        .ant-btn.common-secondary-btn,
        .ant-btn.common-btn {
          height: 50px;
          width: 105px;
        }

        .card-title {
          color: $gray;
          font-weight: 400;
          font-size: 14px;
        }

        .card-value {
          font-size: 16px;
          margin-bottom: 0;
        }

        .date-time-wrapper {
          margin-top: 10px;
          .card-value {
            margin-left: 5px;
          }
        }

        .service-wrapper {
          .card-value {
            margin-left: 10px;
          }

          &__child {
            width: fit-content;
            margin-right: 15px;
          }
        }
      }
      .appointment-wrapper-ongoing {
        background: $white;
        padding: 20px;
        border-radius: 15px;
        margin-bottom: 15px;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
        transition: 0.3s ease all;
        position: relative;
        cursor: pointer;

        border: 2px solid transparent;
        border-color: #02e09e;
        &:hover {
          border-color: #02e09e;
        }

        .person-name {
          font-weight: 700;
          font-size: 20px;
        }

        .service-fee {
          span {
            font-size: 14px;
          }

          border-radius: 8px;
          text-align: center;
          padding: 7px 9px;
          color: $white;
          width: fit-content;
          background: $primary;
          font-size: 16px;
          margin-bottom: 20px;
        }

        .ant-btn.common-secondary-btn,
        .ant-btn.common-btn {
          height: 50px;
          width: 105px;
        }

        .card-title {
          color: $gray;
          font-weight: 400;
          font-size: 14px;
        }

        .card-value {
          font-size: 16px;
          margin-bottom: 0;
        }

        .date-time-wrapper {
          margin-top: 10px;

          .time-wrapper {
            margin-left: 0;
          }

          .card-value {
            margin-left: 5px;
          }
        }

        .service-wrapper {
          .card-value {
            margin-left: 10px;
          }

          &__child {
            width: 35%;
            margin-right: 15px;
          }
        }
      }
    }

    .appointments-listing::-webkit-scrollbar {
      display: none;
    }
  }
}

// details page styles
.appointment-details-container {
  .details-header {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    color: $gray;
    margin-bottom: 15px;

    span {
      color: $black;
    }
  }

  .appointment-details-wrapper {
    background-color: $white;
    height: calc(100vh - 218px);
    overflow: auto;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;

    .common-tag-status {
      border-radius: 30px;
      padding: 8px 20px;
      font-weight: 600;
      font-size: 16px;

      &.status-tag {
        // background: #e8f2fd;
        color: #1079e8;
      }

      &.upcoming-tag {
        background: rgba(82, 247, 177, 0.1);
        color: #52f7b1;
      }
      &.ongoing-tag {
        background: $theme-gradient;
        color: $white;
      }
    }

    .person-name {
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .card-title {
      color: $gray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 5px;
    }

    .card-value {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      &.mt-10-custom {
        margin-top: 10px;
      }
    }

    .address-wrapper {
      .card-value {
        margin-bottom: 0;
      }
    }

    .date-time-wrapper {
      .time-wrapper {
        margin-left: 10px;
      }

      .card-value {
        margin-left: 5px;
        margin-bottom: 0;
      }
    }

    .service-wrapper {
      .card-value {
        margin-left: 10px !important;
      }
    }

    .additional-info {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }

    .ant-row {
      margin-bottom: 20px;
    }
  }

  .bottom-appointment-btn-row {
    // padding-top: 120px;

    .common-secondary-btn,
    .common-btn {
      width: auto;
      padding: 0 50px;
      height: 50px;
    }

    .common-secondary-btn {
      &:hover {
        background: $gray-5;
      }
    }
  }
}
