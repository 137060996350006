@import "../../../styles/theme.scss";

.circular-checkbox .ant-checkbox-inner {
  border-radius: 50%;
  width: 16px; /* Adjust the size as needed */
  height: 16px; /* Adjust the size as needed */
  background-color: white; /* Default background color */
  border: 1px solid black; /* Default border color */
}



.circular-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color:  $primary-dark; /* Change the outer circle color when checked to black */
  border: 1px solid  $primary-dark; /* Change the border color when checked to black */
}

.availability-container {
  .availability-heading {
    color: #112047;
  }

  .availability-wrapper.edit {
    width: 100%;
    height: 80vh;
  }

  .availability-wrapper {
    border: 1px solid $gray-2;
    background: $white;
    border-radius: 15px;
    width: 90%;
    margin: auto;
    min-width: 800px;
    padding: 1rem 2rem;
    height: 78vh;
    overflow-y: auto;

    .datepicker-wrapper {
      background: $gray-4;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;

      .month-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        padding-top: 8px;

        .month-label {
          font-size: 18px;
          font-weight: 600;
          margin: 0 10px 0 10px;
        }
      }

      .datepicker {
        padding-bottom: 8px; 
        
        &__item__active {
          margin: 0 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: $primary-dark;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          padding: 8px 14px;
          box-sizing: border-box;
          cursor: pointer;
          color: $white;

          &__day {
            font-weight: 400;
            line-height: 18px;
            font-size: 10px;
            color: $white;
          }

          &__date {
            color: $white;
          }
          
          span {
            line-height: 1.25;
          }
        }

        &__item__inactive {
          margin: 0 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          cursor: pointer;

          &__day {
            font-weight: 400;
            line-height: 18px;
            font-size: 10px;
            color: $gray;
          }

          &__date {
            color: $black;
          }
        }
      }
    }

    .date-listing {
      padding-top: 25px;
      height: fit-content;
      overflow-y: scroll;

      .date-item {
        .current-date {
          font-size: 18px;
          font-weight: 600;
          margin: 0 50px;
        }
        .edit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: fit-content;  
        }
        .add-edit-time-wrapper {
          margin: 0 50px;

          .time-slot {
            font-size: 16px;
            font-weight: 600;
          }

          .add-text {
            margin-bottom: 0;
            color: $gray;
          }
        }
      }
    }
  }

  .availability-footer {
    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 1px;
    }

    button {
      width: fit-content;
      padding: 0 1.5rem;
      height: 60px;
      color: #FFFFFF;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      border: none;
      outline: none;
      border-radius: 70px;
      background: #B9CDD9;
      cursor: pointer;
    }

    .right button {
      padding: 0 3rem;
    }

    button.active {
      cursor: pointer;
      background: linear-gradient(135deg, #02E09E 0%, #66B6FA 100%);
    }
  }
}

.availability-modal-container {
  .modal-title {
    color: $primary;
    font-size: 30px;
    font-weight: 700;
    margin-top: 20px;
  }

  .date-time-wrapper {
    padding: 20px 60px;

    .selected-date {
      font-size: 20px;
      font-weight: 510;
      line-height: 24px;
    }

    .repeat-txt {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $gray;
    }

    .day-tag-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .day-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.5px solid $primary-light;
        border-radius: 50%;
        padding: 10px;
        font-size: 14px;
        font-weight: 700;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }

      .day-tag-selected {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.5px solid $primary-light;
        border-radius: 50%;
        padding: 10px;
        font-size: 14px;
        font-weight: 700;
        width: 40px;
        height: 40px;
        cursor: pointer;
        background-color: $primary-dark !important;
        color: white;
      }
    }

    .timepicker-wrapper {
      display: flex;
      justify-content: space-between;

      .ant-picker {
        height: 70px;
        width: 160px;
        border-radius: 30px;
        background-color: $gray-4;

        input {
          padding-left: 5px;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .submit-btn {
      background: $theme-gradient;
      width: 235px;
      height: 70px;
      padding: 20px 10px;
      color: $white;
      border-radius: 50px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}