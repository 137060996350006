.logout-modal {
  .no-gradient {
    background-image: linear-gradient(to bottom right, #02e09e, #66b6fa);
  }
  .button-common {
    font-weight: 600;
    border-radius: 30px;
    height: 60px;
    color: #fff;
    font-size: 15px;
    min-width: 150px;
  }
  .confirm-logout-btn {
    cursor: pointer;
    font-weight: 700;
    min-width: 150px;
  }
}
