$primary: #112047;
$primary-dark: #112047;
$primary-light: #59637f;
$theme-gradient: linear-gradient(135deg, #02e09e 0%, #66b6fa 100%);
$white: #ffffff;
$black: #000000;
$gray: #777d88;
$gray-3: #f4f4f4;
$gray-5: #e7e8ea;
$gray-2: #f7f7f7;
$gray-4: #f3f6f9;
$gray-light: #adbdcc;
$gray-rgba-1: rgba(17, 32, 71, 0.11);
$gray-rgba-2: rgba(255, 255, 255, 0.6);
$black-rgba-1: rgba(0, 0, 0, 0.1);
$light-blue: #b9cdd9;
