.app-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 500px; */
    height: 490px;
    padding: auto;
    gap: 20px;
  }

  .ant-modal .ant-modal-content{
    border-radius: 15px !important;
  }
  
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .logout-img {
    width: 145px;
    height: 145px;
    border-radius: 50%;
  }
  
  .heading {
    font-size: 28px;
    color: #000000;
  }
  
  .yes-btn {
    width: 235px;
    height: 70px;
    border-radius: 70px;
    gap: 10px;
    padding: 20px 10px 20px 10px;
    background-color: #ffffff;
    border: 0px;
    color: #000000;
    text-align: center;
    font-weight: 700;
    line-height: 20.16px;
    cursor: pointer;
  }
  
  
  .no-btn {
    width: 235px;
    height: 70px;
    border-radius: 70px;
    gap: 10px;
    padding: 20px 10px 20px 10px;
    background-image: linear-gradient(to bottom right, #02e09e, #66b6fa);
    border: 0px;
    color: #ffffff;
    text-align: center;
    font-weight: 700;
    line-height: 20.16px;
    cursor: pointer;
  }
  