@import "src/styles/theme";
.img-fluid-services {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  object-fit: cover;
}
.layout-container {
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
  .main-content {
    width: calc(100% - 295px);
    margin-left: 295px;
    margin-top: 90px;
  }
}
.store-btn-img{
  height:45px;
  border-radius: 15px;
 }

.navbar-custom {
  width: calc(100% - 307px);
  top: 0;
  left: 307px;
  height: 90px;
  position: fixed;
  z-index: 9;
  background-color: #f8f8f8;
  h3 {
    color: $primary;
    font-size: 30px;
    font-weight: 700;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      &.list-inline-item:not(:last-child) {
        margin-right: 2rem;
      }
      .ant-btn {
        &.dropdown-btn {
          color: $black;
          font-size: 16px;
          font-weight: 700;
          line-height: normal;
          box-shadow: none;
          height: auto;
          outline: none !important;
          .img-fluid {
            transition: 0.3s ease all;
            &.img-profile {
              width: 46px;
              height: 46px;
              border-radius: 50%;
            }
            &.img-arrow {
              transform: rotate(0);
            }
          }
        
          span {
            display: inline-block;
            padding: 0 10px;
          }
          &:hover {
            color: $black;
            box-shadow: none;
            outline: none !important;
          }
          &.ant-dropdown-open {
            .img-arrow {
              transform: rotate(180deg);
            }
          }
        }
      }
      .bedge-icon {
        background: #e04a4a;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 10px;
        font-weight: 700;
        position: absolute;
        top: 0;
        right: 0;
        width: 15px;
        height: 15px;
      }
    }
  }
}
.custom-dropdown {
  .ant-dropdown-menu {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: $white;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    padding: 15px;
    overflow-y: auto;
    height: 420px;
    .ant-dropdown-menu-item {
      padding: 6px;
      background: transparent;
      .ant-btn {
        box-shadow: none;
        border-radius: 10px;
        background: $gray-4;
        height: 70px;
        display: flex;
        align-items: center;
        color: $black;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        width: 100%;
        border: 0;
        .icon-card-d {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $primary;
          margin-right: 15px;
        }
      }
      &:hover {
        background: transparent;
      }
    }
  }
  &.custom-dropdown-notifications {
    width: 375px;
    .notification-row-main {
      border-radius: 10px;
      border: 1px solid $gray-4;
      padding: 15px 0;
      transition: 0.3s ease all;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #e04a4a;
        opacity: 0;
        transition: 0.3s ease all;
      }
      .notification-row-main-no {
        border-radius: 10px;
        border: 1px solid $gray-4;
        padding: 15px 0;
        transition: 0.3s ease all;
        position: relative;
      }
      &:after {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 0;
        transition: 0.3s ease all;
      }
      p {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }
      h6 {
        color: $gray;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
      }
      &.unread-message {
        background: $gray-4;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  &.custom-dropdown-notifications {
    width: 375px;
    .notification-row-main-no {
      border-radius: 10px;
      border: 1px solid $gray-4;
      padding: 15px 0;
      transition: 0.3s ease all;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 0;
        transition: 0.3s ease all;
      }
      .notification-row-main-no {
        border-radius: 10px;
        border: 1px solid $gray-4;
        padding: 15px 0;
        transition: 0.3s ease all;
        position: relative;
      }
      &:after {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 0;
        transition: 0.3s ease all;
      }
      p {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }
      h6 {
        color: $gray;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
      }
      &.unread-message {
        background: $gray-4;
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
.custom-dropdown-navbar {
  .ant-dropdown-menu {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: $white;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
    padding: 15px;
    overflow-y: auto;
    height: auto;
    .ant-dropdown-menu-item {
      padding: 6px;
      background: transparent;
      .ant-btn {
        box-shadow: none;
        border-radius: 10px;
        background: $gray-4;
        height: 70px;
        display: flex;
        align-items: center;
        color: $black;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        width: 100%;
        border: 0;
        justify-content: flex-start;
        .icon-card-d {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $primary;
          margin-right: 15px;
        }
      }
      &:hover {
        background: transparent;
      }
    }
  }
  &.custom-dropdown-notifications {
    width: 375px;
    .notification-row-main {
      border-radius: 10px;
      border: 1px solid $gray-4;
      padding: 15px 0;
      transition: 0.3s ease all;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #e04a4a;
        opacity: 0;
        transition: 0.3s ease all;
      }
      .notification-row-main-no {
        border-radius: 10px;
        border: 1px solid $gray-4;
        padding: 15px 0;
        transition: 0.3s ease all;
        position: relative;
      }
      &:after {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 0;
        transition: 0.3s ease all;
      }
      p {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }
      h6 {
        color: $gray;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
      }
      &.unread-message {
        background: $gray-4;
        &:after {
          opacity: 1;
        }
      }
    }
  }
  &.custom-dropdown-notifications {
    width: 375px;
    .notification-row-main-no {
      border-radius: 10px;
      border: 1px solid $gray-4;
      padding: 15px 0;
      transition: 0.3s ease all;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 0;
        transition: 0.3s ease all;
      }
      .notification-row-main-no {
        border-radius: 10px;
        border: 1px solid $gray-4;
        padding: 15px 0;
        transition: 0.3s ease all;
        position: relative;
      }
      &:after {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 0;
        transition: 0.3s ease all;
      }
      p {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }
      h6 {
        color: $gray;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
      }
      &.unread-message {
        background: $gray-4;
        &:after {
          opacity: 1;
        }
      }
    }
  }
}
.footer-main-setting {
  background: none !important;
  position: initial !important;
  ul {
    display: flex;
  }
}
.availability-footer {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  .footer-main {
    width: 100%;
  }
}
.center-items {
  justify-content: center !important;
}
.footer-main {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f8f8f8;
  width: calc(100% - 308px);
  z-index: 1;
  ul {
    text-align: center;
    li {
      &.list-inline-item:not(:last-child) {
        margin-right: 20px;
        font-size: 16px;
      }
      .ant-btn {
        font-size: 1.15rem;
        font-weight: 400;
        line-height: 26px;
        color: $black;
        &:not(:disabled):not(.ant-btn-disabled):hover {
          color: $black;
        }
      }
    }
  }
}
