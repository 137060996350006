@import "src/styles/theme";
.ant-menu-item-active {
  color: white; /* Set the text color to white */
  background-color: #1890ff; /* Set the background color to the active state background color you desire */
}
.active-menu-item{
  color: white;
}
.sidebar-custom {
  background: $primary;
  border-radius: 20px;
  height: calc(100vh - 30px);
  width: 266px;
  position: fixed;
  left: 12px;
  top: 20px;
  overflow: auto;

  .app-logo {
    margin: 20px 0 0 15px;
  }

  .ant-menu {
    .ant-menu-item {
      height: 68px;
      display: flex;
      align-items: center;
      background: transparent;
      font-weight: 700;
      outline: none;
      &.active,
      &:focus-visible {
        outline: none;
        background-color: transparent;
      }
      &:not(.ant-menu-item-selected):hover {
        background-color: transparent;
      }
    }
  }

  .sidebar-toggler {
    background: #fff;
    width: 22px;
    border-radius: 50%;
    padding-left: 3px;
    position: absolute;
    top: 60px;
    right: -10px;
    box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
    cursor: pointer;
    z-index: 99;
  }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 5px 24px;
    margin-bottom: 0;
    text-align: center;
  }

  .ant-menu.ant-menu-dark {
    .ant-menu-item {
      .ant-menu-title-content {
        width: 100%;
        display: flex;
        margin-left: 0;

        img {
          margin-right: 20px;
        }

        span {
          font-size: 16px;
          float: left;
          width: 77px;
          text-align: left;
        }
      }
    }
  }
}

@media (max-width: 412px) {
  .logoutmodal {
    h2 {
      margin-left: 0 !important;
    }
  }
}
