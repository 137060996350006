@import "../../../styles/theme";
//header-css
.active-link {
  border-bottom: 1px solid black;
}
.header-main {
  height: 115px;
  padding: 0;
  transition: 0.3s ease all;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  position: fixed;
  background: $white;
  .logo-div {
    a {
      img {
      }
    }
  }

  .nav-list-ul {
    float: right;
    display: flex;
    align-items: center;

    li {
      a {
        font-size: 18px;
        font-weight: 600;
        color: $gray;
        display: inline-block;
        padding: 12px;
        .active-link{
          color: $black;
          text-decoration: underline !important;
        }
        &:hover, &:focus, &.active {
          color: $black;
          text-decoration: underline !important;
        }
      }

      &.auth-btn-li {
        padding-left: 20px;
      }
    }
  }
  &.is-active-header{
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 10px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
}

.footer-fluid {
  background: $primary;
  padding: 40px 0;

  .container {
    max-width: 1240px;
  }

  .footer-row-1 {
    ul {
      padding: 40px 0;
      li {
        &.list-inline-item:not(:last-child) {
          margin-right: 3rem;
        }

        a {
          font-size: 18px;
          font-weight: 400;
          line-height: normal;
          color: $white;

          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }
  }

  .footer-row-2 {
    p {
      color: $gray-rgba-2;
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
    }

    ul {
      li {
        &.list-inline-item:not(:last-child) {
          margin-right: 2rem;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            right: -18px;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 2px;
            height: 20px;
            background: $white;
            opacity: 0.2;
          }
        }

        a {
          color: $white;
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;

          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }
  }
}
.menu-hamburger-btn, .sidebar-main{
  display: none;
}
