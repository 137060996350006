@import "./src/styles/theme";

.sliding-drawer {
  overflow-y: auto;
  height: 100%;
  z-index: 9999;
  background: #FFFFFF;
  box-shadow: 0.2px 0.6px 0.5px 0.5px;
  border-radius: 20px;
}

.backdrop:after {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
  z-index: -99;
}
