@import "theme";

.check-box-div {
  margin-left: 10px;
  color: $gray;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;

  a {
    font-weight: 700;
    color: $black;
  }
}

.react-tel-input {
  padding: 1rem 8px !important;

  .form-control:focus {
    box-shadow: none;
    width: 100%;
  }
}

#custommobile .react-tel-input .form-control {
  background: inherit;
  width: auto;

}

.react-tel-input .form-control {
  width: 100% !important;
  background: inherit !important;
  height: 100% !important;
  border: none !important;
}

.react-tel-input .flag-dropdown {
  background-color: inherit !important;
  outline: none !important;
  border: none !important;
  border-radius: 70px !important;
}

.common-form-div {
  .ant-form {
    .ant-form-item {
      margin-bottom: 20px;

      .ant-form-item-control-input {
        .ant-input {
          height: 60px;
          background: $gray-4;
          border: 1px solid $gray-3;
          padding: 20px 20px 20px 70px;
          border-radius: 70px;
          font-size: 16px;
          font-weight: 400;
          color: $black;

          &::-webkit-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          &.textarea-message {
            height: 212px;
            border-radius: 20px;
            padding: 20px;
          }

          &.textarea-description {
            height: 80px;
            border-radius: 15px;
            padding: 20px;
          }

          &.textarea-description {
            height: 80px;
            border-radius: 15px;
            padding: 20px;
          }
        }

        .ant-select {
          .ant-select-selector {
            height: 60px;
            background: $gray-4;
            border: 1px solid $gray-3;
            padding: 13px 20px;
            border-radius: 70px;
            font-size: 16px;
            font-weight: 400;
            color: $black;

            .ant-select-selection-search {
              .ant-select-selection-search-input {
                height: 58px;
              }
            }

            .ant-select-selection-placeholder {
              font-size: 16px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }

        .ant-upload-wrapper {
          width: 100%;

          .ant-upload {
            width: 100%;

            .ant-upload {
              width: 100%;
              position: relative;

              .ant-btn {
                height: 60px;
                background: $gray-4;
                border: 1px solid $gray-3;
                padding: 20px 70px 20px 20px;
                border-radius: 70px;
                font-size: 16px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
                width: 100%;
                box-shadow: none;
                text-align: left;
              }
            }
          }
        }

        .ant-input-affix-wrapper {
          height: 60px;
          background: $gray-4;
          border: 1px solid $gray-3;
          padding: 10px 20px 10px 60px;

          border-radius: 70px;

          .ant-input {
            background: transparent;
            height: auto;
            padding: 0;
            border-radius: 0;
            border: none;
          }
        }
      }

      .ant-btn.common-btn {
        padding: 20px 55px;
        margin-top: 15px;

        &.submit-btn {
          padding: 20px 80px;
          margin: auto;
          background: $theme-gradient;
          color: $white !important;
          margin-top: 20px !important;

          &:disabled {
            background: #B9CDD9;
            margin-top: 20px !important;
          }
        }
      }

      .icon-beige-div {
        position: absolute;
        left: 10px;
        top: 0;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $primary;
        border-radius: 50%;
        bottom: 0;
        margin: auto;
        z-index: 1;

        &.close-icon-container {
          background: transparent;
          left: auto;
          right: 15px;
          width: auto;
          height: auto;
          cursor: pointer;
        }
      }

      .ant-checkbox-wrapper {
        color: $gray;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;

        padding-left: 16px;


        a {
          font-weight: 700;
          color: $black;
        }

        .ant-checkbox {
          &:after {
            border-radius: 50%;
          }

          .ant-checkbox-inner {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }

          &.ant-checkbox-checked {
            &:after {
              border-color: transparent;
            }

            .ant-checkbox-inner {
              background: $theme-gradient;
            }
          }
        }

        &:hover {
          .ant-checkbox-inner {
            border-radius: 50%;
          }
        }
      }
    }

    .upload-file-div {
      .icon-beige-div {
        left: auto;
        right: 10px;
      }
    }
  }

  &.settings-form-div {
    .services-list {
      max-height: 60%;
      overflow-y: auto;
    }

    .ant-form {
      .ant-form-item {
        .ant-form-item-control-input {
          .ant-input {
            padding: 20px;
            height: 60px;

            &.textarea-description {
              height: 100px;
            }
          }
        }

        &.phone-number {
          .ant-form-item-control-input {
            .ant-input {
              padding: 20px 20px 20px 70px;
            }
          }
        }
      }
    }
  }

  &.form-create-profile {
    height: fit-content;

    .ant-form {
      .ant-form-item {
        .ant-form-item-control-input {
          .ant-input {
            padding: 16px;
          }
        }
      }

      .mobile-no-div {
        .ant-form-item {
          .ant-form-item-control-input {
            .ant-input {
              padding: 20px 20px 20px 0px;
            }
          }
        }
      }

      .upload-img-div {
        width: 140px;
        height: 140px;
        margin: auto;

        .ant-upload-wrapper {
          .ant-upload.ant-upload-select {
            width: 140px;
            height: 140px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            padding: 0;
            margin: auto;

            .edit-profile-btn.ant-btn {
              right: 0;
              bottom: 0;
              background: $primary;
              border: 2px solid $white;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 44px;
              height: 44px;
              border-radius: 50%;
            }
          }

        }


      }
    }
  }
}