@import "../../../styles/theme.scss";

.my-earnings-container {
  .custom-dashboard-card {
    border-radius: 15px;
    border: 1px solid $gray-5;
    background: $white;
    height: 170px;
    margin-bottom: 10px;

    .card-icon-div {
      width: 60px;
      height: 60px;
      background: $gray-4;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;

      .card-icon {
        width: 49px;
        height: 49px;
      }
    }

    h4 {
      font-weight: 700;
      font-size: 30px;
      margin-top: 0.2rem;
    }

    p {
      color: $gray;
      font-weight: 400;
      font-size: 20px;
      line-height: normal;
      margin-bottom: 0;
    }
  }

  .txns-table-wrapper {
    background-color: $white;
    border-radius: 20px;

    .table-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
    }

    .select-label {
      font-size: 16px;
      font-weight: 400;
      color: $gray;
      margin-bottom: 0;
      margin-right: 10px;
    }

    .txns-table {
      height: 50vh;
      overflow: auto;

      .table-tr {
        padding: 15px;
        .expense-icon-div {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #22c55e;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .expense-details-div {
          padding-left: 15px;

          .expense-heading {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 0;
          }

          .expense-date {
            font-size: 12px;
            font-weight: 400;
          }
        }

        .expense-price {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }

    .txns-table::-webkit-scrollbar {
      display: none;
    }

    .ant-select.year-dropdown {
      .ant-select-selector {
        border-radius: 55px;
        border: 1px solid $gray;
        padding: 0 30px 0 20px;
        height: 35px;

        .ant-select-selection-search-input {
          height: 40px;
        }

        .ant-select-selection-item {
          font-weight: 600;
          font-size: 16px;
          color: $black;
          line-height: 40px;
        }
      }

      .ant-select-arrow {
        inset-inline-end: 20px;
      }
    }
  }
}
