@import "../../styles/theme";

.mobile-header-custom {
  height: 80px;
  background: $primary;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  left: 0;
}
