@import "src/styles/theme";
.dashboard-container {
  max-height: calc(100vh - 140px);
}

.dashboard-heading {
  margin-bottom: 1rem;

  h5 {
    font-weight: 700;
    font-size: 26px;
  }

  p {
    color: $gray;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
  }
}

.custom-dashboard-card {
  border-radius: 15px;
  border: 1px solid #e4e4e7;
  background: $white;
  height: fit-content;

  .ant-card-body {
    padding: 1rem 1.5rem;
    height: fit-content;
    box-sizing: border-box;

    .card-icon-div {
      width: fit-content;
      height: fit-content;
      background: $gray-4;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      padding: 8px 12px;

      .card-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.chart-card-main {
  // min-height: 415px;
  border-radius: 15px;
  border: 1px solid #e4e4e7;
  background: $white;
  max-height: calc(100vh - 390px);
  margin-bottom: 50px;
  box-sizing: border-box;
  overflow-y: auto;

  .chart-content {
    font-weight: 700;
    font-size: 20px;

    .buttons {
      button {
        outline: none;
        border: none;
        padding: 6px 15px;
        border-radius: 55px;
        background: #fff;
        box-sizing: border-box;

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }

      .active {
        border: 1.25px solid #777d88;
      }
    }
  }

  .chart {
    height: 330px;
    // @media screen and (max-height: 700px) {
    //   margin-bottom: 40px;
    // }

    .recharts-surface {
      height: 332px;
    }

    .recharts-cartesian-grid-horizontal line {
      stroke-dasharray: 0;
      height: 2px;
      opacity: 0.25;
    }

    .recharts-cartesian-grid-vertical line {
      display: none;
    }

    .custom-tooltip {
      background-color: #ffffff;
      padding: 3px 14px;
      font-weight: 600;
      font-size: 12px;
      color: #111111;
      text-align: center;
      border-radius: 6px;
      height: 50px;
      -moz-box-shadow: 0 0 8px #c7c7c7;
      -webkit-box-shadow: 0 0 8px #c7c7c7;
      box-shadow: 0 0 8px #c7c7c7;
      border: 1px solid rgba(181, 181, 181, 0.5);

      p {
        margin-bottom: 0px;
        opacity: 0.8;
      }

      p:nth-child(2) {
        opacity: 1;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .custom-tooltip::after {
      content: "";
      position: absolute;
      top: 98%;
      left: 50%;
      margin-left: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: #ffffff transparent transparent transparent;
    }

    // y axis first line remove
    .recharts-cartesian-grid-horizontal line:first-child {
      display: none;
    }

    .recharts-tooltip-cursor {
      display: none;
    }

    .recharts-xAxis {
      transform: translate(0, 10px);
    }
    .session-duration-tooltip {
      max-width: 250px;
      position: relative;
      padding: 0.5em;
      border-radius: 5px;
      background-color: var(--bg-color);
      color: aliceblue;
    }

    .session-duration-tooltip::after {
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: var(--bg-color) transparent transparent transparent;
    }
  }
}
