@import "styles/theme";

body {
  font-family: "Wix Madefor Display", sans-serif;
  font-size: 14px;
}

.App {
  text-align: left;
}

a,
a:hover {
  transition: 0.3s ease all;
  text-decoration: none !important;
}

button {
  transition: 0.3s ease all;
}

.ant-btn {
  &.common-secondary-btn {
    height: 55px;
    padding: 20px 10px;
    border-radius: 70px;
    background: $gray-5;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
    font-size: 16px;
    font-weight: 700;
    &:hover,
    &:focus {
      color: $primary !important;
      background: $white;
      outline: none;
    }
  }
  &.common-btn {
    height: 52px;
    padding: 20px 40px;
    border-radius: 70px;
    background: $theme-gradient;
    color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
    font-size: 16px;
    font-weight: 700;

    &:before {
      content: "";
      background: $theme-gradient;
      position: absolute;
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      z-index: -1;
      border-radius: 70px;
    }

    &:not(:disabled):not(.ant-btn-disabled):hover {
      background: $theme-gradient;
      color: $white !important;
      &:before {
        background: $theme-gradient;
      }
    }

    &.sign-in-btn {
      color: $primary;
      background: $white;
      margin-right: 10px;

      &:hover {
        background: $theme-gradient;
        color: $white !important;
      }
    }

    &:hover,
    &:focus {
      outline: none;
      &:before {
        background: $theme-gradient;
      }
    }

    &.signup-banner {
      padding: 20px 55px;
    }

    &.steps-btn {
      width: 235px;
    }

    &:disabled {
      background: #b9cdd9;
      color: $white !important;

      &:before {
        background: #b9cdd9;
      }
    }
  }
}

.common-heading {
  margin-bottom: 30px;

  .heading-tag {
    padding: 10px 25px;
    border-radius: 60px;
    background: $gray-rgba-1;
    width: auto;
    display: inline-block;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.2px;
    color: $primary;
  }

  h5 {
    margin: 15px 0;
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    color: $primary;
  }

  p {
    color: $gray;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
  }
}

::-webkit-scrollbar {
  background: transparent;

  width: 0;
}

.mobile-header-custom {
  display: none;
}

.tc-privacy {
  .back-button {
    margin-left: -1.5rem;
    margin-top: 1rem;
    cursor: pointer;
    border: 1.5px solid black;
    width: fit-content;
    border-radius: 50%;
    padding: 6px;

    img {
      width: 32px;
      height: 32px;
    }
  }

  .tc-conditions {
    border-radius: 20px;
    background: $gray-2;
    padding: 30px 15px;
    margin-bottom: 15px;
    margin-top: 5rem;

    h5 {
      color: $primary;
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
    }

    p {
      color: $gray;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .tc-conditions-inner {
    height: calc(100vh - 280px);
    overflow: auto;

    h5,
    h6 {
      color: $black;
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
    }

    p,
    ul,
    li {
      color: $gray;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }
  }

  .full-height {
    height: 100%;
  }
}
.contentright .ant-form-item .ant-select-single {
    font-size: 14px;
    height: 58px;
}
