@import "src/styles/theme";

.common-heading {
  margin-bottom: 0;
  h6 {
    color: $primary;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 15px;
  }
}

.notifications-row {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: $white;
  padding: 15px 0;
  margin: 0;

  .notification-row-main {
    border-radius: 10px;
    border: 1px solid $gray-4;
    padding: 15px 0;
    transition: 0.3s ease all;
    position: relative;
    margin-bottom: 15px;
    background: $gray-4;

    &:after {
      content: '';
      position: absolute;
      top: 10px;
      right: 10px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #E04A4A;
      opacity: 0;
      transition: 0.3s ease all;
    }

    p {
      color: $black;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0;
    }

    h6 {
      color: $gray;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      opacity: 0.5;
    }

    &.unread-message {
      &:after {
        opacity: 1;
      }
    }
  }
}
