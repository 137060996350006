.ratingwrapper {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--White, #fff);
  padding: 30px;
  min-height: 350px;

  .ant-progress .ant-progress-bg {
    background-color: #ff9f29 !important;
  }

  .ant-progress.ant-progress-status-success .ant-progress-bg {
    background-color: #ff9f29 !important;
  }
  .ant-rate-star-zero .ant-rate-star-first svg,
  .ant-rate-star-zero .ant-rate-star-second svg {

    path {
      stroke: #ff9f29;
      stroke-width: 50;
      fill: transparent;
    }
  }
  .ratings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    background-color: white;
    padding: 15px;
    border-radius: 16px;
    border: 1px solid #e2e8f0;
    background: #fff;
    box-shadow: 0px 14px 44px 0px rgba(47, 57, 90, 0.1);
    
    .rleft {
      width: 50%;
      .rscore {
        span {
          font-weight: 600;
          color: black;
          font-size: 25px;
        }
      }

      .rhead {
        margin-bottom: 10px;
      }
    }
    .rright {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      .rightinfo {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-end;
        .progressbar {
          justify-content: flex-end;
          height: 10px;
          width: fit-content;
          font-size: 10px;
          padding-bottom: 20px;
          padding-right: 10px;

          .ratingbar {
            display: flex;
            .div1 {
              margin-top: 4px;
              margin-right: 10px;
              font-size: 10px;
              font-weight: 500;
              color: var(--Grey, #777d88);
            }
            .progressb {
              width: 140px;
              height: 10px;

              .ant-progress-inner {
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
  }
  .userrating-heading {
    margin-top: 2rem;
    font-family: "Wix Madefor Display";
    font-size: 18px;
    font-weight: 700;
  }
  .peopleratings {
    height: 50vh;
    overflow-y: scroll;
    padding: 1rem 0;
    box-sizing: border-box;

    .peoplerating {
      display: flex;
      justify-content: space-between;
      border-radius: 20px;
      margin-top: 15px;
      .singlerating {
        display: flex;
        .ratingname {
          font-size: 16px;
          font-weight: 500;
        }
        .rating {
          color: #787878;
          font-family: "Wix Madefor Display", sans-serif;
          font-size: 14px;
          font-weight: 400;
        }
        .staricon {
          width: 50px;
          height: 50px;
          background-color: #fff5ea;
          border-radius: 10px;
          align-items: center;
          display: flex;
          justify-content: center;
          margin-right: 10px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .ratingtime {
        font-size: 12px;
        color: gray;
      }
    }
  }
}
