@import "../../../styles/theme.scss";

// help page styling
.contact-us-container {
  background-color: white;
  height: calc(100vh - 175px);
  overflow: auto;
  padding: 40px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .email {
    font-size: 30px;
    font-weight: 700;
    color: $primary;
  }

  .helper-text {
    font-size: 20px;
    font-weight: 500;
    color: $gray;
  }

  .email-us-btn {
    display: initial;
    width: 235px;
    height: 70px;
    padding: 20px 10px;
  }

  .helper-text-wrapper {
    margin: 20px 0 40px 0;
  }
}

// help page styling
.help-container {
  .search-wrapper {
    &__input {
      height: 60px;
      padding: 20px 20px 20px 10px;
      border-radius: 50px;
      border: 1px solid rgba(119, 125, 136, 0.25);
      background: $gray-4;
      &:hover{
        border-color: rgba(119, 125, 136, 0.25);
      }
      .ant-input{
        background: transparent;
        font-size: 16px;
        font-weight: 500;
        padding-left: 10px;
      }
    }

    input {
      padding-left: 5px;
    }

    input::placeholder {
      color: black;
      font-weight: 600;
    }
  }

  .help-content-wrapper {
    height: calc(100vh - 236px);
    overflow: auto;

    .ant-collapse-expand-icon{
      align-items: inherit !important;
    }

    .ant-collapse {
      border: none;
      background: #f8f8f8;

      &-item {
        padding: 5px 10px;
        background: $white;
        border-radius: 15px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin: 20px 0;
      }

      &-header {
        color: $black;
        font-size: 20px;
        font-weight: 600;
      }

      &-content {
        border-top: none;
      }

      &-content-box {
        padding: 16px 16px 0 16px;
      }

      .panel-content {
        font-size: 16px;
        color: $gray;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }

  .help-content-wrapper::-webkit-scrollbar {
    display: none;
  }
}
