@import "../../styles/theme";

.banner-fluid-main {
  background: linear-gradient(127.47deg, #01B8C1 2.78%, #0B2749 91.39%);
  padding: 115px 15px 0;

  .banner-details-row {
    .left-side-content {
      h1 {
        font-size: 85px;
        font-weight: 800;
        line-height: 107.1px;
        text-align: left;
        color: $white;
        margin-bottom: 40px;
      }

      p {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: $white;
        margin-bottom: 0;
      }
    }

    .right-side-img {
      bottom: 0;
      right: -90px;

      ul {
        position: absolute;
        bottom: 90px;
        right: 60px;

        li {
          margin: 15px 0;

          button {
            img {
              height: 85px;
            }
          }
        }
      }
    }
  }
}

.about-us-fluid {
  padding: 90px 15px 30px;
  overflow: hidden;

  h2 {
    font-size: 45px;
    font-weight: 700;
    line-height: 56.7px;
    margin-bottom: 26px;
    color: $black;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.20000000298023224px;
    margin-bottom: 26px;
    color: $gray;
    padding-right: 7%;
  }

  .about-us-img {
    position: relative;
    right: -23%;
  }
}

.business-owners-fluid {
  padding: 0;
  background: linear-gradient(121.1deg, #01B8C1 7.12%, #0B2749 109.28%);
}

.business-owners-row {
  .main-connect {
    min-height: 333px;
    padding: 40px 50px 40px 100px;
    background: #112047;

    .number-value {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      background: #00BCC5;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px;
      font-size: 25px;
      font-weight: 700;
      line-height: 31.5px;
      text-align: left;
      color: #112047;
    }

    p {
      font-size: 25px;
      font-weight: 500;
      line-height: 31.5px;
      text-align: left;
      color: $white;
    }

    &.connect-2 {
      background: #071332;

      p {
        color: #57E4FF;
      }
    }
  }
}

.heading-main {
  padding: 40px 15px 60px;

  .info-heading-tag {
    background: #FFFFFF1C;
    padding: 10px 15px;
    border-radius: 60px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.20000000298023224px;
    color: $white;
    margin: 0 auto 20px;
    width: 210px;
  }

  h2 {
    font-size: 45px;
    font-weight: 700;
    line-height: 56.7px;
    text-align: center;
    color: $primary;
    margin-bottom: 20px;

    span {
      color: $white;
    }
  }

  p {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    color: $white;
    margin-bottom: 0;
  }
}

.feature-fluid {
  padding: 90px 15px 0;

  .feature-tag {
    width: 103px;
    height: 48px;
    padding: 10px 15px 10px 15px;
    gap: 10px;
    border-radius: 60px;
    background: #1120471C;
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.20000000298023224px;
    color: $primary;
    margin: 0 auto 32px;
  }

  .featured-details-row {
    margin-top: 30px;

    h6 {
      font-size: 25px;
      font-weight: 700;
      line-height: 31.5px;
      text-align: center;
      color: $black;
      margin: 16px 0 16px;
    }

    .card-box-feature {
      min-height: 316px;
      background: #00C4CC;
      border-radius: 30px 30px 0 0;
      padding: 20px;
      width: 100%;

      p {
        font-size: 18px;
        font-weight: 700;
        line-height: 30.6px;
        text-align: left;
        color: $white;
      }
    }
  }
}

.crafting-excellence-fluid {
  box-shadow: 0px -14px 34px 0px #0000001A;
  background: #112047;
  padding: 40px 15px;
  overflow: hidden;

  .heading-craft-main {
    margin-bottom: 60px;

    h2 {
      font-size: 45px;
      font-weight: 700;
      line-height: 56.7px;
      color: $white;
      margin-bottom: 10px;

      span {
        color: #00C4CC;
      }
    }

    p {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: $white;
    }
  }

  .service-tag {
    position: relative;
    right: -23%;

    li {
      position: relative;
      height: 96px;
      background: #00C4CC;
      margin: 24px 0;
      font-size: 32px;
      font-weight: 700;
      line-height: 36px;
      text-align: right;
      color: $white;
      padding: 10px 90px 10px 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -96px;
        width: 0;
        height: 0;
        border: 48px solid;
        border-color: transparent #00C4CC transparent transparent;
        margin: auto;
      }
    }
  }
}

.for-suers-fluid {
  padding: 90px 0 0;

  .for-suers-heading {
    margin-bottom: 60px;

    .for-users-tag {
      background: #1120471C;
      width: 109px;
      height: 48px;
      padding: 10px 15px 10px 15px;
      gap: 10px;
      border-radius: 60px;
      font-size: 17px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.20000000298023224px;
      text-align: center;
      color: $primary;
    }

    h3 {
      margin: 20px 0;
      font-size: 45px;
      font-weight: 700;
      line-height: 56.7px;
      color: #00BCC5;
    }

    p {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: #777D88;
    }
  }
}

.get-started-fluid {
  padding: 60px 15px 0;
  background: $white;
  background: $white;

  h3 {
    font-size: 45px;
    font-weight: 700;
    line-height: 56.7px;
    color: #00BCC5;
    margin-bottom: 20px;
  }

  p {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 60px;
    color: #777D88;
  }

  ul {
    li {
      .ant-btn {
        box-shadow: none;
        border: 0;
        padding: 0;
        background: transparent;
        border-radius: 0;

        img {
          height: 77px;
        }
      }
    }
  }
}

.little-bit-about-fluid {
  padding: 160px 15px;
  background: $white;
  overflow: hidden;
  .common-layer {
    position: absolute;
    width: 180px;
    height: 180px;
    &.layer-top-right {
      top: -90px;
      right: -160px;
    }

    &.layer-bottom-left {
        left: -90px;
        bottom: -30px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .padding-left {
    padding-left: 80px;
  }

  h4 {
    font-size: 45px;
    font-weight: 700;
    line-height: 56.7px;
    color: $black;
    margin-bottom: 26px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.20000000298023224px;
    margin-bottom: 40px;
    color: $gray;
  }
}

.footer-common {
  background: $primary;
  padding: 50px;

  .contact-us-details-main {
    background: $white;
    padding:50px;

    .arrow-icon-up {
      right: 100px;
      top: -30px;
    }

    .contact-us-details {
      h6 {
        font-size: 28px;
        font-weight: 700;
        color: $primary;
        margin-bottom: 45px;
      }
    }
  }

  .footer-second-details {
    margin-top: 70px;

    .footer-nav-link {
      li {
        margin: 10px 0;

        a {
          display: inline-block;
          width: 100%;
          font-size: 18px;
          font-weight: 400;
          line-height: 22.68px;
          text-align: left;
          color: $white;
        }
      }
    }

    .footer-social-link {
      li.list-inline-item:not(:last-child) {
        margin-right: 24px;
      }
    }
    .footer-bottom-details {
        padding-top: 40px;
        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 22.68px;
          color: #ffffff60;
        }
      }
  }
}