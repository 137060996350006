.payment-wrapper {
  .card-element-wrapper {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-top: 1.5rem;

    .ant-form-item {
      margin-bottom: 0px;
    }

    .error {
      color: #ff4d4f;
    }

    .holder-name input, .holder-name input::placeholder {
      font-weight: 500;
      font-size: 16px;
      color: #111111;
    }

    .card-number {
      width: 100%;
      height: 60px;

      .InputContainer input {
        font-weight: 500;
        font-size: 16px !important;
        color: #111111;
        text-transform: capitalize;
      }
    }

    .card-number,
    .card-expiry,
    .card-cvv {
      background: #f3f6f9;
      padding: 19px 16px;
      border-radius: 70px;
    }
  }
}