@media only screen and (min-width: 320px) and (max-width: 767px) {
    .store-btn-img {
        width: 140px;
        height: 45px;
        border-radius: 10px;
    }
.status-tag-col-canceled{
    top: 65px;
}
    .header-main {
        padding: 0 15px;
    }

    .availability-container .availability-wrapper {
        min-width: unset !important;
    }

    .datepicker {
        justify-content: unset !important;
        overflow: auto;
    }

    .menu-hamburger-btn {
        display: block;
        background-color: transparent;
        border: 0;
        padding: 0;
        height: auto;
        margin-left: auto;
        width: 50px;
        box-shadow: none;
    }

    .header-main img {
        height: 50px;
    }

    .arrow-down-div {
        right: 45px;
        bottom: -77px;
    }

    .banner-fluid .banner-row-details h1 {
        font-size: 35px;
    }

    .banner-fluid .banner-row-details {
        padding-left: 0;
    }

    .banner-fluid {
        padding: 0 15px 30px;
    }

    .banner-fluid .banner-right-div img {
        height: auto;
    }

    .how-it-works-fluid {
        padding: 30px 0 0;
    }

    .common-heading h5 {
        font-size: 25px;
    }

    .common-heading p {
        font-size: 14px;
        line-height: 26px;
    }

    .how-it-works-fluid .work-card-row {
        padding: 15px 10px;
    }

    .how-it-works-fluid .work-card-row h5 {
        font-size: 20px;
    }

    .how-it-works-fluid .work-card-row p {
        font-size: 14px;
        line-height: 26px;
    }

    .how-it-works-fluid .work-card-row .work-icon-div {
        width: 75px;
        height: 75px;
        margin-bottom: 15px;
    }

    .sky-quick-web-fluid {
        padding: 40% 0;
        height: 1289px;
        /* position: static; */
    }

    .sky-quick-web-fluid .common-heading.sky-web-details {
        padding: 0 0 60px 0;
    }

    .sky-quick-web-fluid .sky-web-img img {
        height: auto;
    }

    .how-it-works-fluid.our-features-fluid {
        padding: 0;
    }

    .contact-us-fluid .common-heading {
        padding-right: 15px;
    }

    .contact-us-fluid .contact-us-details h6 {
        font-size: 18px;
        margin-bottom: 30px;
        margin-top: 90px;
    }

    .contact-us-fluid .common-heading h5 .arrow-icon-up {
        display: none;
    }

    .common-heading .heading-tag {
        padding: 8px 20px;
        font-size: 13px;
    }

    .contact-us-fluid {
        padding: 30px 0 60px;
    }

    .footer-fluid .footer-row-1 ul {
        padding: 30px 0;
    }
    .appointment-details-container .bottom-appointment-btn-row > div{
        width: 100%;
    }
    .appointment-details-container .bottom-appointment-btn-row ul{
        display: flex;
        justify-content: space-between;
    }
    .appointment-details-container .bottom-appointment-btn-row ul li{
        width: 49%;
    }
    .appointment-details-container .bottom-appointment-btn-row .common-secondary-btn, .appointment-details-container .bottom-appointment-btn-row .common-btn{
        font-size: 14px;
    }
    .footer-fluid .footer-row-1 ul li.list-inline-item:not(:last-child) {
        margin-right: 1rem;
    }

    .footer-fluid .footer-row-2 p {
        text-align: center;
        padding-bottom: 15px;
    }

    .footer-row-2 .list-inline.mb-0.text-end {
        text-align: center !important;
    }

    .banner-fluid .banner-row-details .details-inner {
        margin-top: 30px;
        align-items: flex-start;
    }

    .banner-fluid .banner-row-details .details-inner .details-img {
        /* width: 145px; */
        display: none;
    }

    .banner-fluid .banner-row-details .details-inner .details-des {
        width: 100% !important;
        padding: 0 0 10px 15px;
    }

    .banner-fluid .banner-row-details .details-inner .details-des p {
        font-size: 12px;
        margin: 15px 0;
        line-height: 21px;
    }

    .ant-btn.common-btn.signup-banner {
        width: 100%;
        padding: 10px 55px;
        height: 45px;
    }

    .banner-fluid .banner-row-details h1 img.line-arrow-shape-img {
        bottom: -25px;
        height: 50px;
    }

    .banner-fluid .banner-right-div {
        padding: 20px 0 0;
    }

    .banner-fluid .banner-row-details h1 img.rounded-shape-img {
        left: -9px;
        top: -1px;
        height: 48px;
    }

    .sky-quick-web-fluid .sky-bottom-layer {
        bottom: 139px;
        height: 57px;
    }

    .sidebar-main {
        background: #112047;
        display: block;
        padding: 60px 25px;
        left: -100%;
        height: 100vh;
        top: 0;
        overflow: auto;
        transition: 0.3s ease all;
        z-index: 3;
    }

    .sidebar-main.sidebar-main-inner {
        padding: 20px 25px 30px;
    }

    .header-sidebar {
        border-bottom: 1px solid #40507c;
        padding-bottom: 20px;
    }

    .sidebar-main.sidebar-toggle {
        left: 0;
    }

    .sidebar-main .nav-list-ul li a {
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        display: inline-block;
        width: 100%;
        padding: 10px 0;
    }

    .sidebar-main .nav-list-ul.nav-list-ul-inner-list {
        padding-top: 20px;
    }

    .sidebar-main .nav-list-ul.nav-list-ul-inner-list li a {
        display: flex;
        align-items: center;
        padding: 15px 0;
        color: rgba(255, 255, 255, 0.6);
        font-size: 16px;
        height: 50px;
        font-weight: 500;
    }

    .sidebar-icon {
        width: 38px;
        position: relative;
    }

    .sidebar-icon img {
        transition: 0.3s ease all;
        width: 24px;
        height: 25px;
    }

    .sidebar-icon img.white-icon {
        opacity: 0;
        position: absolute;
        top: 5px;
        left: 0;
    }

    .sidebar-text {
        width: calc(100% - 38px);
    }

    .sidebar-main .nav-list-ul.nav-list-ul-inner-list li a:hover,
    .sidebar-main .nav-list-ul.nav-list-ul-inner-list li a.active {
        color: #ffffff;
    }

    .sidebar-main
    .nav-list-ul.nav-list-ul-inner-list
    li
    a:hover
    .sidebar-icon
    img.gray-icon,
    .sidebar-main
    .nav-list-ul.nav-list-ul-inner-list
    li
    a.active
    .sidebar-icon
    img.gray-icon {
        opacity: 0;
    }

    .sidebar-main
    .nav-list-ul.nav-list-ul-inner-list
    li
    a:hover
    .sidebar-icon
    img.white-icon,
    .sidebar-main
    .nav-list-ul.nav-list-ul-inner-list
    li
    a.active
    .sidebar-icon
    img.white-icon {
        opacity: 1;
    }

    .bottom-logout {
        margin-top: 60px;
    }

    .sidebar-main .nav-list-ul li.auth-btn-li {
        margin-top: 30px;
    }

    .sidebar-close-btn {
        background-color: #ffffff;
        padding: 8px;
        border: 0;
        box-shadow: none;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .ant-modal.common-modal .ant-modal-content {
        border-radius: 0 !important;
        padding: 0;
        height: 100vh;
    }

    .auths-main-row .auths-img-div img {
        display: none;
    }

    .ant-modal.common-modal {
        width: 100% !important;
        height: 100vh;
        max-width: 100vw;
    }

    .auths-main-row {
        height: 100vh;
    }

    .auths-main-row .auths-details {
        padding: 30px;
    }

    .ant-modal.common-modal.common-modal-tc .ant-modal-content {
        padding: 30px 0;
    }

    .ant-modal.common-modal.common-modal-tc .ant-modal-content .tc-conditions {
        border-radius: 0;
        background: transparent;
        padding: 30px 0;
        margin-bottom: 0;
    }

    .ant-modal.common-modal.common-modal-tc .ant-modal-content .tc-conditions h5 {
        font-size: 22px;
    }

    .ant-modal.common-modal.common-modal-tc
    .ant-modal-content
    .tc-conditions-inner {
        height: calc(100vh - 240px);
        overflow: auto;
    }

    .tc-conditions .text-center {
        text-align: left !important;
    }

    .common-modal-tc .auths-main-row {
        align-items: flex-start;
        height: auto;
    }

    .common-modal-tc .auths-main-row .auths-details {
        padding: 0 30px;
    }

    .ant-modal.common-modal.common-modal-tc .ant-modal-content {
        height: auto;
    }

    .create-profile-main {
        padding: 100px 0 110px;
    }

    .create-profile-main .container .create-profile-heading {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin: 0 0 30px;
        padding-bottom: 0;
        position: relative;
        width: 100%;
        left: 0;
        padding-top: 0;
        z-index: 0;
    }

    .create-profile-main .container .common-steps-row {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .create-profile-main .container .create-profile-heading .text-center {
        text-align: left !important;
    }

    .create-profile-main .container .create-profile-heading h1 {
        font-size: 22px;
    }

    .create-profile-main .container .create-profile-heading p {
        font-size: 16px;
        line-height: 28px;
    }

    .mobile-header-custom {
        display: block !important;
        height: 60px;
    }

    .mobile-header-custom a img {
        height: 35px;
    }

    .create-profile-main .container .common-steps-row .ant-steps {
        border: none;
        background: transparent;
        padding: 0;
        margin-right: 0;
        width: 100%;
        float: none;
        flex-direction: row;
        position: relative;
    }

    .create-profile-main
    .container
    .common-steps-row
    .ant-steps
    .ant-steps-item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .create-profile-main .container .common-steps-row .ant-steps-item {
        width: 25%;
        text-align: center;
        padding: 0 10px;
    }

    .create-profile-main
    .container
    .common-steps-row
    .ant-steps
    .ant-steps-item
    .ant-steps-item-container
    .ant-steps-item-tail {
        padding: 15px 0 0 35px;
        width: 102%;
        height: 2px;
        inset-inline-start: 23px;
    }

    .create-profile-main
    .container
    .common-steps-row
    .ant-steps
    .ant-steps-item
    .ant-steps-item-container
    .ant-steps-item-tail:after {
        width: 100%;
        height: 2px;
    }

    .create-profile-main
    .container
    .common-steps-row
    .ant-steps
    .ant-steps-item
    .ant-steps-item-container
    .ant-steps-item-content
    .ant-steps-item-title {
        font-size: 12px;
        padding: 10px 0 0;
        line-height: initial;
    }

    .create-profile-main
    .container
    .common-steps-row
    .ant-steps
    .ant-steps-item
    .ant-steps-item-container
    .ant-steps-item-icon {
        margin-inline-end: 0;
    }

    .create-profile-main
    .container
    .common-steps-row
    .ant-steps
    .ant-steps-item
    .ant-steps-item-container
    .ant-steps-item-content {
        min-height: 70px;
    }

    .create-profile-main .container .common-steps-row .steps-content {
        float: none;
        width: 100%;
        min-height: auto;
        padding: 15px;
        height: auto;
        overflow: auto;
        margin: 0;
    }

    .create-profile-main
    .container
    .common-steps-row
    .steps-content
    .steps-form-heading
    h5 {
        margin-bottom: 20px;
        font-size: 22px;
        text-align: left;
    }

    .common-form-div.form-create-profile
    .ant-form
    .ant-form-item
    .ant-form-item-control-input
    .ant-input {
        padding: 10px 20px;
    }

    .common-form-div
    .ant-form
    .ant-form-item
    .ant-form-item-control-input
    .ant-input {
        height: 56px;
        padding: 10px 20px 10px 70px;
    }

    .d-non-mobile {
        display: none;
    }

    .create-profile-main .steps-action.steps-action-custom {
        width: 100% !important;
        height: 90px;
        background: #ffffff;
        left: 0;
        position: fixed;
        bottom: 0;
        align-items: center;
        display: flex;
        justify-content: center;
        z-index: 1;
    }

    .common-form-div
    .ant-form
    .ant-form-item
    .ant-form-item-control-input
    .ant-select
    .ant-select-selector {
        height: 56px;
        padding: 10px 20px;
    }

    .common-form-div
    .ant-form
    .ant-form-item
    .ant-form-item-control-input
    .ant-select
    .ant-select-selector
    .ant-select-selection-search
    .ant-select-selection-search-input {
        height: 54px;
    }

    .common-form-div.form-create-profile .ant-form .upload-img-div {
        width: 100px;
        height: 100px;
        margin: 0 auto 25px;
    }

    .common-form-div.form-create-profile
    .ant-form
    .upload-img-div
    .ant-upload-wrapper
    .ant-upload.ant-upload-select {
        width: 100px;
        height: 100px;
    }

    .common-form-div.form-create-profile
    .ant-form
    .upload-img-div
    .edit-profile-btn {
        width: 35px;
        height: 35px;
    }

    .common-form-div.form-create-profile .ant-btn.common-btn {
        width: 100%;
        height: 48px;
    }

    .common-form-div
    .ant-form
    .ant-form-item
    .ant-form-item-control-input
    .ant-upload-wrapper
    .ant-upload
    .ant-upload
    .ant-btn
    span {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .create-profile-main .common-card-row {
        min-height: auto;
        padding: 30px 0;
    }

    .create-profile-main .common-card-row .steps-form-heading h5 {
        margin-bottom: 20px;
        font-size: 22px;
        text-align: left;
    }

    .create-profile-main
    .common-card-row
    .service-custom-checkbox
    .ant-checkbox-group
    .ant-checkbox-wrapper {
        margin-bottom: 20px;
    }

    .create-profile-main
    .common-card-row
    .amount-details-row
    .process-div
    .ant-btn.common-btn {
        width: 100%;
        height: 60px;
    }

    .create-profile-main .steps-action.steps-action-custom .ant-btn.skip-btn {
        width: 235px;
        height: 55px;
    }

    .ant-modal.common-modal.auths-modal-small .ant-modal-content {
        padding: 10px;
    }

    .ant-modal.common-modal.logout-modal .ant-modal-content {
        padding: 30px 10px;
    }

    .auths-main-row .auths-details .auth-top-details h5 {
        font-size: 26px;
    }

    .auths-main-row .auths-details .auth-top-details p {
        margin: 15px 0 30px;
    }

    .ant-modal.common-modal.auths-modal-small .ant-modal-content,
    .ant-modal.common-modal.logout-modal .ant-modal-content,
    .ant-modal.common-modal.logout-modal,
    .ant-modal.common-modal.auths-modal-small,
    .auths-modal-small .auths-main-row,
    .logout-modal .auths-main-row {
        height: auto;
    }

    .ant-modal.common-modal.auths-modal-small,
    .ant-modal.common-modal.logout-modal {
        max-width: 335px;
    }

    .ant-modal.common-modal.auths-modal-small .ant-modal-content,
    .ant-modal.common-modal.logout-modal .ant-modal-content {
        border-radius: 15px !important;
    }

    .ant-modal.common-modal.decline-modal
    .ant-modal-content
    .common-div-modal
    h3 {
        font-size: 22px;
        margin: 20px 0;
    }

    .sidebar-custom,
    .navbar-custom,
    .footer-main {
        display: none;
    }

    .layout-container .main-content {
        width: 100%;
        padding: 15px 0;
        margin-top: 60px;
        margin-left: 0;
    }

    .settings {
        padding-bottom: 65px;
    }
    .common-form-div.settings-form-div .ant-form .ant-form-item .ant-form-item-control-input .ant-input {
    padding:10px 20px;
    height: 45px;
}
    .common-form-div .ant-form .ant-form-item .ant-form-item-control-input .ant-input {
    height: 45px;
    padding: 10px 20px 10px 70px;
    font-size: 13px;
}
    .common-form-div .ant-form .ant-form-item {
    margin-bottom: 10px;
}
.payment-wrapper .card-element-wrapper {
    margin-bottom: 15px;
    padding-top: 0.5rem;
}
.col-12.col-sm-12.col-md-6.mb-3{
    margin-bottom: 0 !important;
}
.payment-wrapper .card-element-wrapper .card-number {
    width: 100%;
    height: 45px;
}
.ratingwrapper {
    padding: 10px;
    min-height: 106px;
}
.ant-col.d-flex.appointment-col.flex-column.px-3.ant-col-xs-24.ant-col-md-12.ant-col-lg-6{
    padding: 0 0 10px !important;
}
.ratingwrapper .peopleratings {
    height: 25vh;
    padding:0;
}
.ratingwrapper .userrating-heading {
    margin-top: 1rem;
    font-size: 16px;
}
.ratingwrapper .ratings {
    align-items: baseline;
    flex-direction: column;
    height: auto;
    padding: 10px;
}
.ratingwrapper .ratings .rleft {
    width: 100%;
}
.ratingwrapper .ratings .rright {
    width: 100%;
    justify-content: flex-start;
}
.ratingwrapper .ratings .rright .rightinfo {
    align-items: flex-start;
}
.payment-wrapper .card-element-wrapper .card-number, .payment-wrapper .card-element-wrapper .card-expiry, .payment-wrapper .card-element-wrapper .card-cvv {
    padding: 10px 20px;
}
    .settings .ant-tabs .ant-tabs-nav {
        height: auto;
        width: 100%;
        margin-bottom: 15px;
        position: relative;
    }

    .settings .ant-tabs {
        display: block;
    }

    .settings .ant-tabs .ant-tabs-nav-list {
        border-radius: 15px;
        padding: 15px;
        height: auto;
        width: 100%;
    }

    .settings .ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
        padding: 0 10px 10px;
    }

    .settings .ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
        padding: 0 10px;
    }

    .settings .ant-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
        font-size: 13px;
        padding: 0 10px;
        height: 50px;
        margin-top: 10px;
    }

    .imageicons img {
        height: 35px !important;
        width: 35px !important;
    }

    .settings .ant-tabs .ant-tabs-content-holder {
        width: 100%;
        margin: 0;
    }

    .settings .ant-tabs .ant-tabs-content .ant-tabs-tabpane {
        width: 100%;
        padding-left: 0;
    }

    .settings .ant-tabs .ant-tabs-content-holder .setting-card {
        border-radius: 15px;
        padding: 10px;
        margin: 0 0 30px;
    }

    .settings .ant-tabs .ant-tabs-content-holder .setting-card .heading {
        font-size: 18px;
        font-weight: 700;
    }

    .settings .ant-tabs .ant-tabs-content-holder .setting-card .settings-form-div .category-input {
        height: 45px;
        font-size: 13px;
        padding: 0 15px;
    }

    .settings .ant-tabs .ant-tabs-content-holder .setting-card .paragraph {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .settings .content {
        flex-direction: column;
        margin-bottom: 10px !important;
    }

    .settings .content .image-upload {
        width: 70px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 15px;
    }

    .settings .content .image-upload .ant-upload {
        width: 70px;
        height: 70px;
    }

    .settings .contentright {
        width: 100%;
    }

    .settings .input1 {
        flex-direction: column;
    }

    .settings .input1 .ant-form-item.w-75 {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 10px !important;
    }

    .settings .input1 .ant-form-item.w-50 {
        width: 100% !important;
        margin-bottom: 10px !important;
    }

    .settings .input1 .ant-form-item.w-50 .ant-select-single {
        height: 43px;
    }

    .settings .ant-tabs .ant-tabs-content-holder .setting-card .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 45px;
    }

    .settings .service-type-input {
        height: 45px;
    }

    .settings .input-field {
        height: 45px;
        font-size: 13px;
    }

    .settings .btn-container {
        justify-content: center;
        margin: 0 !important;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 65px;
        background: #ffffff;
        left: 0;
        z-index: 9;
        align-items: center;
    }

    .settings .btn-container .ant-btn-primary {
        width: 70%;
        height: 45px;
        font-size: 14px;
    }

    .settings .btn-container .ant-btn-primary {
        width: 150px;
        height: 45px;
        padding: 9px 10px;
    }

    .common-form-div.settings-form-div
    .ant-form
    .ant-form-item
    .ant-form-item-control-input
    .ant-input {
        padding: 10px 20px;
    }

    .common-form-div
    .ant-form
    .ant-form-item
    .ant-form-item-control-input
    .ant-upload-wrapper
    .ant-upload
    .ant-upload
    .ant-btn {
        height: 56px;
        padding: 13px 55px 13px 20px;
    }

    .settings .img-profile-container,
    .settings .ant-tabs .ant-tabs-content-holder .setting-card .file-upload-box1 {
        width: 100px;
        height: 100px;
    }

    .settings .ant-tabs .ant-tabs-content-holder .setting-card .editIcon img {
        height: 40px;
    }

    .common-form-div
    .ant-form
    .ant-form-item
    .icon-beige-div.close-icon-container
    img {
        height: 35px;
        width: 35px;
    }

    .content.col-12 {
        display: inline-block !important;
    }

    .settings .imgleft {
        width: 100px;
        height: 100px;
        margin: 0 auto 25px;
    }

    .settings .service-type-input,
    .settings .service-type-fee {
        width: 100% !important;
    }

    .delete-icon {
        position: absolute;
        right: 0;
        top: 4px;
    }

    .settings .dlt-service-icon {
        width: 30px;
        height: 30px;
        margin-left: 0;
        position: absolute;
        top: 40px;
        right: 0;
    }

    .settings .add-services-container {
        margin-bottom: 0;
    }

    .common-form-div.settings-form-div .services-list {
        max-height: 66%;
    }

    .settings .add-more-container {
        padding: 10px 15px;
    }

    .settings .add-more-container .ant-btn {
        width: 100%;
        margin-bottom: 10px;
    }

    .settings .add-more-container .ant-btn {
        height: 40px;
        font-size: 12px;
        padding: 10px;
        margin-bottom: 0;
    }

    .create-profile-main.modal-fluid-main .common-card-row {
        padding: 0;
        overflow: auto;
        height: 350px;
    }

    .payment-modal .create-profile-main.modal-fluid-main .common-card-row {
        height: 460px;
    }

    .create-profile-main.modal-fluid-main
    .common-card-row
    .steps-form-heading
    h5 {
        margin-bottom: 20px;
        font-size: 18px;
    }

    .create-profile-main.modal-fluid-main
    .common-card-row
    .service-custom-checkbox
    .ant-checkbox-group
    .ant-checkbox-wrapper {
        margin-bottom: 15px;
    }

    .create-profile-main.modal-fluid-main .container .create-profile-heading {
        margin: 0 0 10px;
    }

    .create-profile-main.modal-fluid-main .container .create-profile-heading h1 {
        font-size: 22px;
    }

    .create-profile-main.modal-fluid-main .container .create-profile-heading p {
        font-size: 16px;
    }

    .settings-service-modal .create-profile-main.modal-fluid-main {
        padding: 30px 0;
        height: 75vh;
    }

    .ant-modal.common-modal.settings-service-modal .ant-modal-content,
    .settings-service-modal.ant-modal.common-modal {
        height: auto;
    }

    .settings-service-modal.ant-modal.common-modal {
        max-width: 95vw;
    }

    .ant-modal.common-modal.settings-service-modal .ant-modal-content {
        border-radius: 15px !important;
    }

    .create-profile-main.modal-fluid-main
    .common-card-row
    .service-custom-checkbox
    .ant-checkbox-group
    .ant-checkbox-wrapper
    .ant-checkbox {
        right: 15px;
        top: 25px;
    }

    .create-profile-main.modal-fluid-main
    .common-card-row
    .amount-details-row
    .process-div
    .ant-btn.common-btn {
        height: 55px;
    }

    .ant-modal.common-modal.decline-modal .ant-modal-content {
        padding: 30px;
    }

    .dashboard-heading {
        margin-bottom: 20px;
    }

    .dashboard-heading h5 {
        font-size: 22px;
    }

    .custom-dashboard-card {
        min-height: 106px;
        margin-bottom: 0;
    }

    .custom-dashboard-card .ant-card-body h4 {
        font-size: 24px;
        margin-top: 0;
        position: absolute;
        top: 2px;
        left: 0;
    }

    .custom-dashboard-card .ant-card-body p {
        font-size: 18px;
        position: absolute;
        left: 0;
        top: 35px;
    }

    .chart-card-main {
        min-height: auto;
    }

    .chart-card-main.ant-card .ant-card-body {
        padding: 15px;
    }

    .chart-card-main .chart-content {
        margin: 0;
    }

    .chart-card-main .recharts-responsive-container,
    .chart-card-main .recharts-wrapper {
        height: auto !important;
        max-width: 100% !important;
        min-width: 100% !important;
            max-height: 250px !important;
    }
    .chart-card-main .chart {
    height: 250px;
}
    .chart-card-main {
    max-height: initial;
    margin-bottom: 30px;
    overflow-y: initial;
}
    .chart-card-main .chart-content .buttons button {
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 11px;
}
    .chart-card-main .chart-content .fw-bold{
        font-size: 16px !important;
    }
    .chart-card-main .chart-content .buttons{
        column-gap: 2px !important;
    }
    .chart-card-main .recharts-surface {
        height: 250px !important;
    }

    .appointment-dashboard .appointment-tab .ant-tabs-nav-list {
        width: 100%;
    }

    .appointment-dashboard .appointment-tab .ant-tabs-tab {
        width: 33.33%;
        height: 50px;
    }

    .appointment-dashboard .appointment-tab .ant-tabs-nav {
        margin-bottom: 20px;
    }

    .appointment-dashboard .appointment-tab .appointments-listing {
        height: calc(100vh - 205px);
        overflow: auto;
    }

    .appointment-dashboard
    .appointment-tab
    .appointments-listing
    .appointment-wrapper
    .service-fee {
        width: fit-content;
        font-size: 14px;
        margin-bottom: 0;
        font-weight: 700;
        position: absolute;
        top: 0;
        right: 0;
    }

    .appointment-dashboard
    .appointment-tab
    .appointments-listing
    .appointment-wrapper {
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: none;
        padding: 15px;
    }

    .appointment-dashboard
    .appointment-tab
    .appointments-listing
    .appointment-wrapper
    .service-fee
    span {
        font-size: 12px;
        display: block;
    }

    .appointment-dashboard
    .appointment-tab
    .appointments-listing
    .appointment-wrapper
    .card-title {
        font-size: 12px;
        padding: 10px 0 5px;
    }

    .appointment-dashboard
    .appointment-tab
    .appointments-listing
    .appointment-wrapper
    .service-wrapper__child {
        width: 40%;
        margin-right: 15px;
    }

    .appointment-col {
        padding: 0 0 15px !important;
        border: 0 !important;
    }
    .appointment-dashboard .appointment-tab .appointments-listing .appointment-wrapper .person-name .opacity-50:first-child{
        display: none;
    }
    .appointment-dashboard .appointment-tab .appointments-listing .appointment-wrapper .date-time-wrapper {
    margin-top: 0;
}
    .appointment-dashboard .appointment-tab .appointments-listing .appointment-wrapper-ongoing .service-fee {
    font-size: 10px;
    margin-bottom: 0;
}
    .appointment-dashboard .appointment-tab .appointments-listing .appointment-wrapper-ongoing .service-fee span {
    font-size: 12px;
}
    .appointment-dashboard .appointment-tab .appointments-listing .appointment-wrapper-ongoing .person-name {
    font-size: 18px;
    display: flex;
    flex-direction: column;
}
    .appointment-dashboard .appointment-tab .appointments-listing .appointment-wrapper-ongoing .person-name span{
        display: none;
    }
.services-wrapper.d-flex{
    padding-bottom: 0 !important;
}
    .appointment-dashboard
    .appointment-tab
    .appointments-listing
    .appointment-wrapper
    .person-name {
        display: flex !important;
        flex-wrap: wrap !important;
        flex-direction: column !important;
        gap: 8px !important;
        width: 55%;
    }

    .action-btn-col ul li {
        width: 48%;
    }

    .action-btn-col ul,
    .appointment-dashboard
    .appointment-tab
    .appointments-listing
    .appointment-wrapper
    .ant-btn.common-secondary-btn,
    .appointment-dashboard
    .appointment-tab
    .appointments-listing
    .appointment-wrapper
    .ant-btn.common-btn {
        width: 100%;
    }

    .appointment-details-container .details-header {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .appointment-details-container .appointment-details-wrapper .person-name {
        font-size: 20px;
        margin: 4px 0 0;
    }

    .appointment-details-container .appointment-details-wrapper {
        height: auto;
        padding: 15px;
        border-radius: 15px;
        margin-bottom: 90px;
        position: relative;
    }

    .status-tag-col {
        position: absolute;
        top: 15px;
        width: auto;
        right: 126px;
        display: none !important;
    }

    .appointment-details-container
    .appointment-details-wrapper
    .common-tag-status {
        font-weight: 500;
    }

    .appointment-details-container .appointment-details-wrapper .card-title {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .address-wrapper {
        margin-bottom: 1rem;
    }

    .appointment-details-container .appointment-details-wrapper .card-value {
        font-weight: 500;
        line-height: initial;
    }

    .appointment-details-container .bottom-appointment-btn-row {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.07);
        background: #ffffff;
        height: 90px;
        padding: 0 15px;
        display: flex;
        align-items: center;
    }

    .my-earnings-container .custom-dashboard-card {
        height: 106px;
        margin-bottom: 20px;
    }

    .my-earnings-container .custom-dashboard-card .ant-card-body .col-12 {
        position: relative;
    }

    .select-year-div {
        display: block !important;
    }

    .my-earnings-container .txns-table-wrapper .select-label {
        font-size: 12px;
        margin-bottom: 5px;
        margin-right: 0;
    }

    .my-earnings-container
    .txns-table-wrapper
    .ant-select.year-dropdown
    .ant-select-selector {
        padding: 0 20px 0 15px;
        height: 35px;
    }

    .my-earnings-container
    .txns-table-wrapper
    .ant-select.year-dropdown
    .ant-select-selector
    .ant-select-selection-search-input {
        height: 31px;
    }

    .my-earnings-container
    .txns-table-wrapper
    .ant-select.year-dropdown
    .ant-select-selector
    .ant-select-selection-item {
        font-size: 14px;
        line-height: 34px;
    }

    .my-earnings-container
    .txns-table-wrapper
    .ant-select.year-dropdown
    .ant-select-arrow {
        inset-inline-end: 15px;
    }

    .my-earnings-container
    .txns-table-wrapper
    .txns-table
    .table-tr
    .expense-price {
        font-size: 18px;
    }

   .contact-us-container {
        height: auto;
        padding: 30px 15px;
    }

    .contact-us-container img {
        width: auto !important;
        height: 100px;
    }

    .contact-us-container .helper-text-wrapper {
        margin: 15px 0;
    }
    .ant-btn.common-btn {
    height: 45px;
    padding: 10px 40px;
    font-size: 14px;
}
    .help-container .search-wrapper__input {
    height: 45px;
    padding: 10px 20px 10px 10px;
    margin-bottom: 10px !important;
}
    .help-container .help-content-wrapper {
    height: calc(100vh - 160px);
}
    .help-container .help-content-wrapper .ant-collapse-header {
    font-size: 14px;
        padding: 0;
        line-height: 21px;
}
    .contact-us-container .email {
        font-size: 14px;
    }

    .contact-us-container .helper-text {
        font-size: 14px;
    }

    .layout-container {
        height: 100vh;
        overflow: auto;
    }

    .help-container .help-content-wrapper .ant-collapse-item {
        margin: 10px 0 0;
    }

    .help-container .help-content-wrapper .ant-collapse-header {
        display: flex;
        align-items: flex-start;
    }
    .help-container .help-content-wrapper .ant-collapse-header .ant-collapse-header-text{
        padding-right: 30px;
    }
    .availability-container .availability-wrapper .date-listing .date-item {
        padding: 0 15px;
    }
    .help-container .help-content-wrapper .ant-collapse-expand-icon {
    position: absolute;
    right: -10px;
    top: 0;
}
    .help-container .help-content-wrapper .ant-collapse-expand-icon img{
        height: 25px !important;
    }
    .availability-container
    .availability-wrapper
    .date-listing
    .date-item
    .current-date {
        font-size: 18px;
        margin: 0;
    }

    .availability-container
    .availability-wrapper
    .date-listing
    .date-item
    .ant-divider-horizontal {
        margin: 15px 0;
    }

    .availability-container
    .availability-wrapper
    .date-listing
    .date-item
    .add-edit-time-wrapper {
        margin: 0;
    }

    .availability-container .availability-wrapper {
        border-radius: 15px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #fff;
        overflow: auto;
    }

    .availability-container .availability-wrapper .datepicker-wrapper {
        padding: 10px 15px 0;
        overflow: auto;
    }

    .availability-container
    .availability-wrapper
    .datepicker-wrapper
    .month-wrapper {
        margin-bottom: 10px;
    }

    .availability-container
    .availability-wrapper
    .datepicker-wrapper
    .month-wrapper
    .month-label {
        font-size: 16px;
    }

    .availability-modal-container .date-time-wrapper {
        padding: 0;
    }

    .availability-modal-container .modal-title {
        font-size: 27px;
        margin-top: 25px;
        width: 100%;
    }

    .availability-modal-container .date-time-wrapper .day-tag-wrapper .day-tag {
        padding: 5px;
        font-size: 12px;
        width: 35px;
        height: 35px;
    }

    .availability-modal-container .date-time-wrapper .timepicker-wrapper {
        display: inline-block;
    }

    .availability-modal-container
    .date-time-wrapper
    .timepicker-wrapper
    .ant-picker {
        height: 60px;
        width: 100%;
        margin-bottom: 15px;
    }

    .availability-modal-container
    .date-time-wrapper
    .timepicker-wrapper
    .ant-picker:nth-child(2) {
        margin-bottom: 0;
    }

    .availability-modal-container .date-time-wrapper .submit-btn {
        width: 100%;
        height: 60px;
        border: none;
    }

    .my-earnings-container .custom-dashboard-card .card-icon-div .card-icon {
        width: 45px;
        height: 45px;
    }

    .create-profile-main .add-service-list-row.add-service-list-row-update,
    .create-profile-main .add-service-list-row {
        margin-top: 0;
        padding: 0 15px;
    }

    .ant-collapse-expand-icon {
        align-items: inherit !important;
    }

    /*new-landing-page*/
    .banner-fluid-main .banner-details-row .left-side-content h1 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 15px;
}
    .banner-fluid-main .banner-details-row .left-side-content p {
    font-size: 12px;
}
        .header-main {
        padding: 0;
        height: 90px;
    }
.banner-fluid-main {
    padding: 110px 0 0;
}
.banner-fluid-main .banner-details-row .right-side-img {
    right: 0;
}
.banner-fluid-main .banner-details-row .right-side-img ul li button img {
    height: 40px;
}
.banner-fluid-main .banner-details-row .right-side-img ul {
   bottom: 60px;
    right: 90px;
}
.business-owners-fluid .container{
    padding: 0;
}
.about-us-fluid h2 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 15px;
}
.about-us-fluid p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
    padding-right: 0;
}
.about-us-fluid .about-us-img {
    right: -32px;
    margin-top: 45px;
}
.about-us-fluid {
    padding: 60px 15px 15px;
}
.heading-main {
    padding: 30px 0;
}
.heading-main h2 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 15px;
}
.heading-main p {
    font-size: 16px;
    line-height: 28px;
}
.business-owners-row .main-connect {
    min-height: auto;
    padding: 30px 15px 30px 30px;
}
.business-owners-row .main-connect .number-value {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    font-size: 22px;
}
.business-owners-row .main-connect p {
    font-size: 20px;
    line-height: 30px;
}
.feature-fluid {
    padding: 60px 0 0;
}
.feature-fluid .featured-details-row h6 {
    font-size: 20px;
    margin: 10px 0;
}
.feature-fluid .featured-details-row h6 br{
    display: none;
}
.feature-fluid .featured-details-row .card-box-feature {
    min-height: auto;
    border-radius: 30px;
    margin-bottom: 30px;
}
.feature-fluid .featured-details-row .card-box-feature p {
    font-size: 16px;
    line-height: 28px;
}
.crafting-excellence-fluid {
    padding: 40px 0;
}
.crafting-excellence-fluid .heading-craft-main h2 {
    font-size: 32px;
    line-height: 46px;
}
.crafting-excellence-fluid .heading-craft-main p {
    font-size: 18px;
}
.crafting-excellence-fluid .service-tag li {
    height: 62px;
    font-size: 18px;
}
.crafting-excellence-fluid .service-tag li:before {
    left: -62px;
    border: 31px solid;
    border-color: transparent #00C4CC transparent transparent;
}
.for-suers-fluid {
    padding: 60px 0 0;
}
.for-suers-fluid .for-suers-heading h3 {
    margin: 15px 0;
    font-size: 32px;
    line-height: 42px;
}
.for-suers-fluid .for-suers-heading p {
    font-size: 16px;
    line-height: 28px;
}
.for-suers-fluid .for-suers-heading {
    margin-bottom: 30px;
}
.for-suers-fluid .container:nth-child(2){
    padding: 0;
}
.get-started-fluid {
    padding: 60px 0;
}
.get-started-fluid h3 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 10px;
}
.get-started-fluid p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 30px;
}
.get-started-fluid ul li .ant-btn img {
    height: 45px;
}
.little-bit-about-fluid .common-layer{
    display: none;
}
.little-bit-about-fluid {
    padding: 0 15px 60px;
    margin-bottom: 0;
}
.little-bit-about-fluid .padding-left {
    padding-left: 12px;
    padding-top: 30px;
}
.little-bit-about-fluid h4 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 15px;
}
.little-bit-about-fluid p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
}
.footer-common, .footer-common .container {
    padding: 0;
}
.footer-common .contact-us-details-main {
    padding: 30px 12px;
    position: relative;
}
.footer-common .contact-us-details-main:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #112047;
    width: 85%;
    height: 1px;
}
    .common-heading h5 {
        font-size: 30px;
    }
.common-heading p{
    font-size: 16px;
    line-height: 28px;
}
.footer-common .contact-us-details-main .arrow-icon-up {
    right: 15px;
    top: auto;
    bottom: 0;
    transform: rotate(75deg);
}
.footer-common .contact-us-details-main .contact-us-details h6 {
    font-size: 22px;
    margin-bottom: 20px;
}
.footer-nav-link{
    padding: 30px 0;
}
.footer-common .footer-second-details {
    margin-top: 0;
    padding: 30px 15px;
}
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .header-main .nav-list-ul li a {
        font-size: 14px;
        padding: 7px 2px;
    }
    .banner-fluid .container{
        max-width: 100%;
    }
    .navbar-custom h3 {
    font-size: 20px;
}
    .navbar-custom ul li.list-inline-item:not(:last-child) {
    margin-right: 1rem;
}
    .navbar-custom ul li .ant-btn.dropdown-btn {
    font-size: 14px;
}
    .navbar-custom ul li .ant-btn.dropdown-btn .img-fluid.img-profile {
    width: 40px;
    height: 40px;
}
    .navbar-custom ul li .ant-btn.dropdown-btn span {
    padding: 0;
}
    .navbar-custom {
    height: 60px;
    width: calc(100% - 224px);
    left: 224px;
}
    .appointment-dashboard .appointment-tab .ant-tabs-tab {
    height: 45px;
}
    .appointment-dashboard .appointment-tab .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 14px;
}
    .appointment-dashboard .appointment-tab .ant-tabs-nav {
    margin-bottom: 15px;
}
    .action-btn-col.d-flex.justify-content-end.align-items-center{
        margin-top: 15px;
    }
    .availability-container .availability-wrapper {
    width: 90%;
    padding: 1rem ;
}
    .availability-container .availability-wrapper .date-listing {
    padding-top: 15px;
}
    .availability-container .availability-wrapper .date-listing .date-item .current-date {
    font-size: 16px;
    margin: 0 14px;
}
    .availability-container .availability-wrapper .ant-divider-horizontal {
    margin: 15px 0;
}
    .availability-container .availability-wrapper .date-listing .date-item .add-edit-time-wrapper {
    margin: 0 15px;
}
    .settings .ant-tabs .ant-tabs-content-holder .setting-card .settings-form-div {
    height: calc(100vh - 370px);
}
    .sidebar-custom .ant-menu .ant-menu-item {
    height: 50px;
}
    .contact-us-container .email {
    font-size: 24px;
}
    .contact-us-container .helper-text {
    font-size: 16px;
    margin: 0;
}
    .dashboard-heading h5 {
    font-size: 18px;
}
    .custom-dashboard-card .ant-card-body p{
        margin-bottom: 0;
    }
    .layout-container .main-content {
    width: calc(100% - 214px);
        margin-left: 214px;
        margin-top: 60px;
        padding-bottom: 50px;
}
    .my-earnings-container .custom-dashboard-card p {
        font-size: 16px;
    }

    .my-earnings-container .custom-dashboard-card h4 {
        font-size: 24px;
    }

    .datepicker {
        justify-content: unset !important;
        overflow: auto;
    }

    .availability-container .availability-wrapper {
        min-width: unset !important;
    }

    .notifcation-username {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 20px;
    }

    .header-main .nav-list-ul li.auth-btn-li {
        padding-left: 15px;
    }

    .ant-btn.common-btn {
        height: 50px;
        padding: 15px 20px;
        width: auto;
        font-size: 15px;
    }

    .ant-btn.common-btn.sign-in-btn {
        width: auto;
    }

    .header-main {
        padding: 0 15px;
        height: 90px;
    }

    .banner-fluid .banner-row-details {
        padding-left: 0;
    }

    .banner-fluid .banner-row-details h1 {
        font-size: 36px;
    }

    .banner-fluid .banner-row-details h1 img.rounded-shape-img {
        left: -10px;
        top: -3px;
        height: 65px;
    }

    .banner-fluid .banner-row-details h1 img.line-arrow-shape-img {
        bottom: -45px;
        height: 75px;
    }

    .banner-fluid .banner-row-details .details-inner {
        margin-top: 30px;
    }

    .banner-fluid .banner-row-details .details-inner .details-img {
        width: 85px;
    }

    .banner-fluid .banner-row-details .details-inner .details-des {
       width: calc(100% - 85px);
        padding: 0 15px;
    }

    .banner-fluid .banner-row-details .details-inner .details-des p {
        font-size: 12px;
        margin: 10px 0;
    }
    .ant-btn.common-btn {
    height: 42px;
    padding: 10px 20px;
    font-size: 14px;
}
    .banner-fluid .banner-right-div img {
        height: 530px;
        object-fit: cover;
    }

    .banner-fluid {
        padding: 0 0 30px;
    }

    .how-it-works-fluid {
        padding: 30px 0;
    }

    .common-heading h5 {
        font-size: 36px;
    }

    .common-heading p {
        font-size: 18px;
        line-height: 30px;
    }

    .how-it-works-fluid .work-card-row h5 {
        font-size: 20px;
    }

    .how-it-works-fluid .work-card-row p {
        font-size: 16px;
        line-height: 30px;
    }

    .sky-quick-web-fluid .common-heading.sky-web-details {
        padding: 0;
    }

    .sky-quick-web-fluid .sky-bottom-layer {
        bottom: -20px;
        height: 120px;
    }

    .how-it-works-fluid.our-features-fluid {
        padding: 45px 0;
    }

    .contact-us-fluid .contact-us-details h6 {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .common-form-div
    .ant-form
    .ant-form-item
    .ant-form-item-control-input
    .ant-input {
        height: 60px;
        padding: 15px 15px 15px 60px;
        font-size: 15px;
    }

    .common-form-div .ant-form .ant-form-item .icon-beige-div {
        width: 35px;
        height: 35px;
        left: 7px;
    }

    .common-form-div
    .ant-form
    .ant-form-item
    .ant-form-item-control-input
    .ant-input.textarea-message {
        height: 150px;
        padding: 15px;
    }

    .common-form-div .ant-form .ant-form-item .ant-btn.common-btn {
        width: 175px;
        margin-top: 0;
    }

    .contact-us-fluid .common-heading h5 .arrow-icon-up img {
        height: 60px;
    }

    .contact-us-fluid .common-heading h5 .arrow-icon-up {
        right: 15%;
        top: -20px;
    }

    .contact-us-fluid {
        padding: 30px 0 60px;
    }

    .footer-fluid .footer-row-1 ul {
        padding: 30px 0;
    }

    .footer-fluid {
        padding: 30px;
    }

    .common-modal .auths-main-row .auths-img-div img {
        height: auto;
    }

    .common-form-div
    .ant-form
    .ant-form-item
    .ant-form-item-control-input
    .ant-input-affix-wrapper {
        height: 50px;
        padding: 15px 15px 15px 60px;
    }

    .auths-main-row .auths-details .bottom-text {
        margin-top: 15px;
    }

    .ant-modal.common-modal .ant-modal-content {
        padding: 20px;
        height: auto;
    }

    .ant-collapse-expand-icon {
        align-items: inherit !important;
    }
    .store-btn-img {
    height: 35px;
    border-radius: 6px;
}
    .ant-btn.common-btn {
    height: 42px;
    padding: 10px 25px;
    font-size: 14px;
}
    .sidebar-custom {
    width: 200px;
}
    .sidebar-custom ul{
        margin-left: 0 !important;
    }
    .sidebar-custom .ant-menu.ant-menu-dark .ant-menu-item .ant-menu-title-content span {
    font-size: 14px;
    width: auto;
}
    .sidebar-custom .ant-menu.ant-menu-dark .ant-menu-item .ant-menu-title-content img {
    margin-right: 10px;
}
    .app-logo.d-lg-ml-3{
        margin-left: 10px !important;
    }
    /*new-landing-page-css*/

    .banner-fluid-main .banner-details-row .left-side-content h1 {
    font-size: 42px;
    line-height: 54px;
    margin-bottom: 20px;
}
.banner-fluid-main .banner-details-row .right-side-img {
    right: 0;
}
.banner-fluid-main .banner-details-row .right-side-img ul li button img {
    height: 55px;
}
.banner-fluid-main .banner-details-row .right-side-img ul {
    bottom: 50px;
    right: 90px;
}
.about-us-fluid {
    padding: 60px 15px 30px;
}
.about-us-fluid h2 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 15px;
}
.about-us-fluid p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 15px;
    padding-right: 0;
}
.about-us-fluid .about-us-img {
    right: -12%;
}
.heading-main h2 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 15px;
}
.business-owners-fluid .container{
    padding: 0;
    max-width: 100%;
}
.heading-main p {
    font-size: 20px;
    line-height: 30px;
}
.heading-main {
    padding: 40px 15px;
}
.business-owners-row{
    margin: 0;
}
.business-owners-row .main-connect .number-value {
    width: 90px;
    height: 90px;
    margin-bottom: 20px;
    font-size: 20px;
}
.business-owners-row .main-connect {
    min-height: 295px;
    padding: 30px 12px;
    background: #112047;
}
.business-owners-row .main-connect p {
    font-size: 20px;
    line-height: 30px;
}
.feature-fluid {
    padding: 60px 0 0;
}
.feature-fluid .featured-details-row h6 {
    font-size: 20px;
    margin: 16px 0;
}
.feature-fluid .featured-details-row h6 br{
    display: none;
}
.feature-fluid .featured-details-row .card-box-feature {
    min-height: 240px;
    border-radius: 30px;
    margin-bottom: 30px;
}
.crafting-excellence-fluid .heading-craft-main h2 {
    font-size: 36px;
    line-height: 46px;
}
.crafting-excellence-fluid .heading-craft-main p {
    font-size: 20px;
    line-height: 30px;
}
.crafting-excellence-fluid .heading-craft-main {
    margin-bottom: 30px;
}
.crafting-excellence-fluid .service-tag li {
    height: 72px;
    margin: 14px 0;
    font-size: 20px;
    line-height: 36px;
    padding: 10px 30px 10px 10px;
}
.crafting-excellence-fluid .service-tag li:before {
    left: -72px;
    border: 36px solid;
    border-color: transparent #00C4CC transparent transparent;
}
.crafting-excellence-fluid .service-tag {
    right: -11%;
}
.for-suers-fluid {
    padding: 60px 0 0;
}
.for-suers-fluid .for-suers-heading {
    margin-bottom: 45px;
}
.for-suers-fluid .for-suers-heading h3 {
    margin: 15px 0;
    font-size: 36px;
    line-height: 46px;
}
.for-suers-fluid .for-suers-heading p {
    font-size: 18px;
    line-height: 30px;
}
.for-suers-fluid .container{
    max-width: 100%;
    padding: 0;
}
.get-started-fluid {
    padding: 60px 0 0;
}
.little-bit-about-fluid {
    padding: 90px 15px 60px;
    background: #ffffff;
    overflow: hidden;
}
.get-started-fluid h3 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 10px;
}
.get-started-fluid p {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 45px;
}
.get-started-fluid ul li .ant-btn img {
    height: 60px;
}
.little-bit-about-fluid .common-layer {
    width: 80px;
    height: 80px;
}
.little-bit-about-fluid .common-layer.layer-top-right {
    top: -90px;
    right: 0;
}
.little-bit-about-fluid .padding-left {
    padding-left: 0;
}
.footer-common {
    padding: 36px 0;
}
.footer-common .contact-us-details-main .arrow-icon-up {
    right: 0;
    top: -75px;
}
.footer-common .contact-us-details-main {
    padding: 30px;
}
.footer-common .footer-second-details {
    margin-top: 45px;
}
.footer-social-link{
    padding-top: 30px;
}
.nav-list-ul li .ant-btn.common-btn{
    margin-left: 0;
}
.little-bit-about-fluid .common-layer.layer-bottom-left {
    left: -15px;
    bottom: 0;
}

}
@media only screen and (min-width: 768px) and (max-width: 1023px){
.banner-fluid-main .banner-details-row .right-side-img ul {
        bottom: 30px;
        right: 30px;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px){
    .footer-social-link{
    padding-top: 0;
}
}
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .header-main .nav-list-ul li a {
        font-size: 15px;
        padding: 9px 3px;
    }

    .ant-modal.common-modal .ant-modal-content {
        height: calc(100vh - 90px);
    }

    .header-main .nav-list-ul li.auth-btn-li {
        padding-left: 0;
    }

    .common-modal .auths-main-row .auths-img-div img {
        height: calc(100vh - 130px);
    }

    .ant-btn.common-btn {
        height: 40px;
        padding: 10px 20px;
        width: auto;
        font-size: 13px;
    }

    .ant-btn.common-btn.sign-in-btn {
        width: auto;
        margin-right: 0;
    }

    .banner-fluid .banner-row-details h1 {
        font-size: 46px;
    }
    .banner-fluid .banner-row-details .details-inner .details-des{
        padding-top: 0;
    }
    .banner-fluid .banner-row-details h1 img.rounded-shape-img {
        height: 75px;
    }

    .banner-fluid .banner-row-details .details-inner .details-des p {
        margin: 15px 0;
        font-size: 13px;
    }

    .sky-quick-web-fluid .sky-bottom-layer {
        bottom: 45px;
        height: 160px;
    }

    .auths-main-row .auths-img-div img {
        height: 475px;
        object-fit: cover;
    }

    .auths-main-row .auths-details .bottom-text {
        margin-top: 15px;
    }

    .create-profile-main {
        padding: 30px 15px 15px;
    }

    .create-profile-main .container .create-profile-heading {
        padding: 20px 0 0;
    }

    .create-profile-main .container .create-profile-heading h1 {
        font-size: 28px;
    }

    .create-profile-main .container .create-profile-heading p {
        font-size: 16px;
    }

    .create-profile-main .container .common-steps-row,
    .create-profile-main .add-service-list-row {
        margin-top: 55px;
        padding-top: 20px;
        padding-bottom: 60px;
    }

    .create-profile-main .container .common-steps-row .ant-steps {
        padding: 30px 15px 0;
    }

    .create-profile-main .common-card-row {
        padding: 1rem;
    }

    .create-profile-main
    .container
    .common-steps-row
    .ant-steps
    .ant-steps-item
    .ant-steps-item-container
    .ant-steps-item-content {
        min-height: 60px;
    }

    .create-profile-main
    .container
    .common-steps-row
    .ant-steps
    .ant-steps-item
    .ant-steps-item-container
    .ant-steps-item-icon {
        width: 25px;
        height: 25px;
    }

    .create-profile-main
    .container
    .common-steps-row
    .ant-steps
    .ant-steps-item.ant-steps-item-finish
    .ant-steps-item-container
    .ant-steps-item-icon
    .ant-steps-icon {
        height: 25px;
    }

    .create-profile-main
    .container
    .common-steps-row
    .ant-steps
    .ant-steps-item
    .ant-steps-item-container
    .ant-steps-item-content
    .ant-steps-item-title {
        font-size: 15px;
    }

    .create-profile-main
    .container
    .common-steps-row
    .ant-steps
    .ant-steps-item
    .ant-steps-item-container
    .ant-steps-item-tail {
        padding: 25px 0 0;
        left: 12px;
    }

    .create-profile-main .container .common-steps-row .steps-content {
        padding: 20px 30px;
    }

    .create-profile-main
    .container
    .common-steps-row
    .steps-content
    .steps-form-heading
    h5 {
        font-size: 18px;
    }

    .create-profile-main .steps-action.steps-action-custom {
        height: 60px;
    }

    .create-profile-main .steps-action.steps-action-custom ul li button {
        font-size: 13px;
        font-weight: 500 !important;
    }

    .ant-btn.common-btn.steps-btn {
        width: auto;
        padding: 10px 50px;
        height: 40px;
        font-size: 12px;
    }

    .common-form-div.form-create-profile .ant-form .upload-img-div {
        width: 110px;
        height: 110px;
        margin: auto;
    }

    .common-form-div.form-create-profile
    .ant-form
    .upload-img-div
    .ant-upload-wrapper
    .ant-upload.ant-upload-select {
        width: 110px;
        height: 110px;
    }

    .common-form-div.form-create-profile
    .ant-form
    .upload-img-div
    .edit-profile-btn {
        width: 35px;
        height: 35px;
    }

    .common-form-div.form-create-profile
    .ant-form
    .upload-img-div
    .edit-profile-btn
    img {
        height: 20px;
    }

    .common-form-div .ant-form .upload-file-div .icon-beige-div {
        left: auto;
        right: 10px;
    }

    .create-profile-main .common-card-row .steps-form-heading h5 {
        font-size: 22px;
    }

    .create-profile-main
    .common-card-row
    .amount-details-row
    .process-div
    .ant-btn.common-btn,
    .create-profile-main .steps-action.steps-action-custom .ant-btn.skip-btn {
        padding: 10px 45px;
        height: 50px;
        width: auto;
        font-size: 13px;
    }

    .create-profile-main .add-service-list-row.add-service-list-row-update {
        margin-top: 1rem;
    }

    .ant-collapse-expand-icon {
        align-items: inherit !important;
    }
}
@media only screen and (min-width: 1201px) and (max-width: 1400px) {
    .settings .add-services-container {
    margin-bottom: 0;
}
    .header-main .container {
    max-width: 1300px;
}
    .settings .ant-tabs .ant-tabs-content-holder .setting-card .settings-form-div {
    height: calc(100vh - 290px);
}
    .settings .ant-tabs .ant-tabs-content-holder .setting-card {
    min-height: 430px;
}
    .input1 .w-75, .input1 .w-50{
        width: 100% !important;
    }
    .settings .input1 {
    flex-direction: column;
}
    .settings .content .image-upload {
    margin:0 auto 15px;
    align-items: flex-start !important;
}
    .settings .contentright{
        width: 100%;
    }
    .settings .dlt-service-icon {
    position: absolute;
    top: 5px;
    right: 0;
}
    .settings .content {
    flex-direction: column;
}
    .sidebar-custom {
    width: 220px;
}
    .layout-container .main-content {
    width: calc(100% - 240px);
    margin-left: 240px;
        margin-top: 80px;
}
    .navbar-custom {
    width: calc(100% - 250px);
    left: 250px;
    height: 80px;
}
.navbar-custom h3 {
    font-size: 26px;
}
.appointment-dashboard .appointment-tab .ant-tabs-tab {
    height: 45px;
}
.appointment-dashboard .appointment-tab .ant-tabs-nav {
    margin-bottom: 15px;
}
.appointment-dashboard .appointment-tab .appointments-listing {
    height: calc(100vh - 175px);
}
.footer-main {
    height: 45px;
    width: calc(100% - 235px);
}
.sidebar-custom .ant-menu .ant-menu-item {
    height: 55px;
}
.header-main {
    height: 80px;
}
.banner-fluid-main .banner-details-row .left-side-content h1 {
    font-size: 70px;
    line-height: 85px;
    margin-bottom: 30px;
}
.banner-fluid-main .banner-details-row .left-side-content p{
    font-size: 14px;
}
.banner-fluid-main .banner-details-row .right-side-img ul li button img {
    height: 60px;
}
.banner-fluid-main .banner-details-row .right-side-img ul {
    bottom: 90px;
    right: 100px;
}
.banner-fluid-main {
    padding: 80px 15px 0;
}
/*new-landing-css*/
.business-owners-fluid .container,
.for-suers-fluid .container, .footer-common .container{
    max-width: 100%;
    padding: 0;
}
.little-bit-about-fluid .common-layer.layer-bottom-left {
    left: -90px;
    bottom: -130px;
}
.little-bit-about-fluid .common-layer.layer-top-right {
    top: -150px;
    right: -90px;
}



}
@media only screen and (min-width: 1401px) and (max-width: 1600px){
.little-bit-about-fluid .common-layer.layer-bottom-left {
    left: -40px;
    bottom: -130px;
}
.little-bit-about-fluid .common-layer.layer-top-right {
    top: -150px;
    right: -40px;
}
    .business-owners-row .main-connect {
    padding: 40px 50px 40px 60px;
}
}
@media only screen and (min-width: 1601px) and (max-width: 1919px){
    .business-owners-row .main-connect {
    padding: 40px 50px 40px 60px;
}
}
@media only screen and (min-width: 1920px) and (max-width: 2500px){
    .about-us-fluid .about-us-img {
    right: -49%;
}
    .business-owners-row .main-connect {
    padding: 40px 50px 40px 60px;
}
}
@media screen and (min-height: 768px) {
    .chart-card-main {
        max-height: calc(100vh - 370px);
    }
}
