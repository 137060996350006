@import "./src/styles/theme";

.auths-main-row {
  .auths-img-div {
    .auth-img-card {
      width: 100%;

      img {
        border-radius: 18px;
        width: 100%;
        height: calc(100vh - 120px);
      }
    }
  }

  .auths-details {
    padding: 0 30px;
    height: fit-content;
    overflow: auto;
    align-items: center;
    top: 5rem;

    .auth-top-details {
      h5 {
        color: $primary;
        font-size: 30px;
        font-weight: 700;
        line-height: normal;
        margin: 20px 0 0;
      }

      p {
        color: $gray;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin: 15px 0 20px;
      }
    }

    .bottom-text {
      margin-top: 20px;

      p {
        color: $gray;
        font-size: 14px;
        margin-bottom: 0;
        margin-top: 20px;

        a {
          font-weight: 700;
          color: $black;

          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }
    &.auths-details-reset {
      height: auto;
    }
  }

  .forgot-password {
    h6 {
      color: $black;
      font-size: 14px;
      font-weight: 700;

      a {
        color: $black;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
}

.common-modal-tc {
  .auths-main-row {
    .auths-details {
      height: auto;
      overflow: initial;
    }
  }
}
.pcHeader {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}
.listShow ul {
  list-style: none;
  padding: 0 ;
}
.listShow li {
  counter-increment: list-item;
  list-style: none;
}
.listShow li:before {
  content: "(" counter(list-item, lower-alpha) ")";
  margin-right: 5px;
}

