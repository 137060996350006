@import "../../../../src/styles/theme.scss";

.settings {
  .heading-container {
  }

  .heading-container {
  }

  .settings-edit-services-row {
    margin-bottom: 30px;
  }

  .short-field {
    width: 600px;
  }

  .image-input {
    margin-left: 120px;
    margin-top: 40px;
    display: none !important;
  }
  .document-container {
    position: relative;
  }
  .input-field {
    height: 60px;
    background-color: $gray-4 !important;
    border: 0px;
    border-radius: 70px;
    font-size: 16px;
    font-weight: 400;

    input::placeholder {
      text-align: left;
    }

    .ant-input-group .ant-input-group-addon {
      border: 0px;
      background-color: $gray-4;
    }

    .ant-input {
      background-color: $gray-4;
    }
  }
  .disabled-email {
    opacity: 0.3;
  }
  .edit-profile-btn.ant-btn {
    right: 0;
    bottom: 0;
    background: $primary;
    border: 2px solid $white;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }

  .edit-pic .ant-upload .ant-btn {
    position: absolute !important;
    left: 4rem;
    top: 5rem;
    background: #112047 !important;
    border: 2px solid #ffffff !important;
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px !important;
    height: 44px !important;
    border-radius: 50% !important;
  }

  :where(
      .css-dev-only-do-not-override-12upa3x
    ).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select {
    background-color: inherit !important;
    border: none !important;
  }
  :where(
      .css-dev-only-do-not-override-14wwjjs
    ).ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select {
    background-color: inherit !important;
    border: none !important;
  }

  .add-services-container {
    margin-bottom: 40px;
  }

  .content {
    display: flex;
    flex-direction: row;

    .image-upload {
      width: 100px;
      margin-right: 15px;

      .ant-upload {
        border-radius: 50%;
      }
    }
  }

  .contentright {
    width: calc(100% - 115px);
    display: flex;
    flex-direction: column;
  }

  .input1 {
    display: flex;
    flex-direction: row;
  }

  .text-area {
    border-radius: 15px !important;
    height: 70px !important;
    background-color: #f3f6f9;
    border: 0px;
  }

  .upload-doc-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .upload-doc-btn {
      width: 600px;
      text-align: left;
    }

    .clear-doc-icon {
      position: relative;
      right: 3rem;
      cursor: pointer;
    }
  }

  .upload-doc-icon {
    left: unset !important;
    right: 10px !important;
  }

  .change-password-input {
    width: 335px !important;

    .ant-input-prefix {
      margin-inline-end: 13px;
    }
  }

  .ant-tabs {
    .ant-tabs-nav {
      width: 365px;
      height: fit-content;

      .ant-tabs-nav-list {
        background: $white;
        border: 1px solid $black-rgba-1;
        border-radius: 10px;
        padding: 20px;
        height: fit-content;
        width: 100%;

        .ant-tabs-tab {
          background-color: $gray-4;
          border-radius: 10px;
          font-weight: 600;
          font-size: 16px;
          width: 100%;
          height: 70px;
          color: $black;
          padding: 0 20px;
          height: 60px;

          &.ant-tabs-tab-active {
            background-color: $primary;
            border: 1px solid #003b5b;
            color: $white;
          }

          &:hover {
            color: $black;
          }
        }

        .ant-tabs-ink-bar {
          display: none;
        }
      }
    }

    .ant-tabs-content-holder {
      border: 0;

      .setting-card {
        width: 100%;
        height: auto;
        max-height: calc(100vh - 220px);
        min-height: 460px;
        background: $white;
        border: 1px solid $black-rgba-1;
        border-radius: 10px;
        overflow-y: auto;
        padding: 1rem 2rem;
        margin: 0;
        position: relative;
        .settings-form-div {
          overflow-y: auto;
          height: calc(100vh - 300px);

          .category-input {
            height: 55px;
            background: $gray-4;
            border: none;
            border-radius: 12px;
            font-size: 16px;
          }
        }
        .main-container {
          width: 600px;
        }

        .heading {
          color: $primary;
          font-weight: 700;
          font-size: 25px;

          &.edit-services-heading {
            margin-bottom: 15px;
          }
        }

        .ant-select-selection-item {
          padding: 5px;
        }

        .lock-icon {
          width: 50px;
          height: 50px;
          gap: 10px;
          cursor: pointer;
        }

        .eye-icon {
          width: 23px;
          height: 23px;
        }

        svg {
          cursor: pointer;
        }

        .file-upload-box1 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: fit-content;
          height: fit-content;
          background-color: $gray-4;
          border-radius: 50%;
          position: relative;

          .ant-upload-select {
            background: none;
            border: none;
            margin: 0;
          }
        }

        .editIcon {
          position: absolute;
          top: 60%;
          left: 70%;
          cursor: pointer;
        }

        .paragraph {
          font-weight: 600;
          font-size: 18px;
          color: $black;
          margin-bottom: 10px;
        }

        .btn-3 {
          border-radius: 50%;
          height: 50px;
          width: 50px;
        }

        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;

          .ant-select-selection-search-input {
            height: 58px;
          }
        }

        .ant-upload {
          width: 100%;
        }

        .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
        }
      }
    }

    @media screen and (max-width: 1192px) {
      flex-direction: column;

      .ant-tabs-nav {
        width: 100% !important;
        margin-bottom: 1rem;
      }

      .ant-tabs-tabpane {
        padding-left: 0 !important;
      }
    }
  }

  .eye-icon {
    margin-inline-start: 4px;
    cursor: pointer;
  }

  .settingnames {
    padding: 10px;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $white !important;
  }

  // .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  //   color: black !important;
  // }

  .ant-select-selector {
    background-color: $gray-4;
    border-radius: 70px;
    border: 0;
    padding-left: 25px;
  }

  .imgleft {
    width: 140px;
    height: 140px;
  }

  .dlt-service-icon {
    width: 50px;
    height: 50px;
    margin-left: 15px;
    cursor: pointer;
    position: relative;
    top: 5px;
  }

  .ant-select-selector {
    width: 600px;
    height: 60px;
    background-color: $gray-4 !important;
    border: 0 !important;
  }

  .service-type-input {
    width: 100% !important;
    height: 60px;
  }

  .img-profile-container {
    width: 140px;
    height: 140px;
    border-radius: 140px;
    background-color: $gray-4;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .add-service-img {
    height: 64px;
    width: 64px;
  }

  .service-type-fee {
    width: 100% !important;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;

    .ant-btn-primary {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      background-image: $theme-gradient;
      width: 150px;
      height: 50px;
      padding: 20px 10px;
      gap: 10px;
      text-align: center;
      border-radius: 70px;
    }

    .disabled {
      background-color: $light-blue;
    }

    .ant-btn {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
  }

  .add-more-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: $white;
    padding: 15px 40px;

    .ant-btn {
      width: auto;
      height: 50px;
      border-radius: 70px;
      border: 0;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      box-shadow: none;
      padding: 10px 1rem;

      &.add-more-btn {
        width: 30%;
        color: $white;
        background-image: $theme-gradient;
      }

      &.add-services-btn {
        width: 65%;
        background-color: $black-rgba-1;
        color: $black;
      }
    }

    .ant-btn.common-btn {
      width: 150px;
      height: 70px;
    }

    .common-btn-dis {
      width: 150px !important;
      height: 70px;
    }
  }
}
