@import '../../../../src/styles/theme.scss';

.center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 500px;
    border: 2px solid rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    margin: auto;
    position: relative;
    top: 15vh;
  
    .forgot-password-heading{
      text-align: center;
      margin: 15px;
      font-weight: 700;
    }
  
    .ant-input{
      background-color: $gray-4;
        
    }
    
    .ant-input-affix-wrapper >input.ant-input{
        padding: 8px;
    }

    .ant-form-item .ant-form-item-explain-error{
        width: 335px;
        margin: auto;
    }

    .input-fields{
      background-color: $gray-4;
      border-radius: 70px;
      border: 0px;
      
    }
  
    .btn-container {
      display: flex;
      justify-content: center;
      margin-top: 15px;
  
      .ant-btn-primary {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-image: $theme-gradient;
        width: 150px;
        height: 50px;
        padding: 20px 10px;
        gap: 10px;
        text-align: center;
        border-radius: 70px;
      }
  
      .ant-btn {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  