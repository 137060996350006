@import "./src/styles/theme";

.ant-modal.common-modal {
  .ant-modal-content {
    border-radius: 20px;
    padding: 15px;
    height: fit-content !important;
    overflow: auto;

    .ant-modal-close {
      width: 40px;
      height: 40px;
      background: transparent;
      top: 20px;
      right: 20px;
    }
  }

  &.auths-modal-small {
    .ant-modal-content {
      padding: 75px;
    }

    &.auths-modal-email {
      .ant-modal-content {
        padding: 50px;
        height: auto;
      }
    }
  }

  &.common-modal-tc {
    .ant-modal-content {
      padding: 30px;
      height: fit-content;

      .tc-conditions {
        border-radius: 20px;
        background: $gray-2;
        padding: 30px 15px;
        margin-bottom: 15px;
        margin-top: 5rem;

        h5 {
          color: $primary;
          font-size: 24px;
          font-weight: 700;
          line-height: normal;
        }

        p {
          color: $gray;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
      }

      .tc-conditions-inner {
        height: calc(100vh - 280px);
        overflow: auto;

        h5,
        h6 {
          color: $black;
          font-size: 18px;
          font-weight: 600;
          line-height: normal;
        }

        p,ul,li{
          color: $gray;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
        }
      }
    }

    .ant-modal-footer {
      .ant-btn.common-btn {
        margin: auto;
        background: $theme-gradient;
        width: 235px;
        color: $white !important;
      }
    }
  }

  &.decline-modal {
    .ant-modal-content {
      padding: 4.6rem;
      border-radius: 15px;

      .common-div-modal {
        img {
          width: 145px;
          height: 145px;
        }

        h3 {
          font-size: 30px;
          font-weight: 700;
          margin: 30px 0;
        }

        .ant-btn {
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          border: none;

          &.no-gradient.button-common {
            background: $theme-gradient;
            height: 70px;
            border-radius: 70px;
            color: $white;
            // padding: 0 24%;
          }

          &.confirm-logout-btn {
            margin-top: 20px;
            color: $black;
            // margin-left: 16px;
          }
        }
      }
    }

    &.close-appointment {
      .ant-modal-content {
        padding: 70px 85px;
        border-radius: 10px;

        h6 {
          padding-top: 10px;
          font-size: 22px;
          font-weight: 500;
          color: $black;
        }

        p {
          font-size: 16px;
          color: $black;
          margin-bottom: 30px;
          font-weight: 400;
          line-height: 26px;

          img {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
}
