@import "../../../styles/theme";

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

.create-profile-main {
  background: #f8f8f8;
  height: 100vh;
  overflow: hidden;
  padding: 1rem;

  .datePicker-dob {
    padding: 1rem 1.25rem;
  }

  .datePicker-dob:hover,
  .datePicker-dob:focus-within,
  .datePicker-dob:focus {
    border: none;
  }

  .datePicker-dob input,
  .datePicker-dob input::placeholder {
    font-size: 16px;
    font-weight: 400;
  }

  .container {
    .heading {
      h1 {
        color: $primary;
        font-size: 36px;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: $gray;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
      }
    }

    .create-profile-heading {
      border-bottom: 1px solid $black-rgba-1;
      padding-bottom: 10px;
      position: fixed;
      z-index: 2;
      width: calc(100% - 144px);
      background: #f8f8f8;
      left: 84px;
      top: 0;
      padding-top: 2rem;
    }

    .common-steps-row {
      margin-top: 150px;
      padding-bottom: 110px;
      height: calc(100vh - 150px);
      position: relative;

      .steps-col {
        display: flex;
        flex-direction: row;
        height: 100%;

        .scroll-div {
          overflow-y: auto;
          height: 50vh;
        }

        .scroll-services {
          overflow-y: auto;
          max-height: 32vh;
          padding: 1rem 0;

          .delete-service {
            cursor: pointer;
          }
        }

        @media screen and (max-width: 1192px) {
          flex-direction: column;

          .ant-steps {
            margin-bottom: 1rem;
          }

          .ant-steps,
          .steps-content {
            width: 100%;
          }

          .scroll-div {
            overflow-y: auto;
            height: fit-content;
          }
        }
      }

      .ant-steps {
        border-radius: 15px;
        border: 1.041px solid $black-rgba-1;
        background: $white;
        padding: 50px 25px 0;
        margin-right: 40px;
        width: 350px;
        z-index: 1;
        height: fit-content;

        .ant-steps-item {
          .ant-steps-item-container {
            .ant-steps-item-tail {
              padding: 30px 0 0;

              &:after {
                width: 2px;
              }
            }

            .ant-steps-item-icon {
              border: 1px solid #d1d5db;
              background: transparent;
              position: relative;

              &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $primary;
                transition: 0.4s ease all;
                opacity: 0;
              }

              .ant-steps-icon {
                display: none;
              }
            }

            .ant-steps-item-content {
              min-height: 76px;

              .ant-steps-item-title {
                color: $gray;
                font-size: 18px;
                font-weight: 400;
              }
            }
          }

          &.ant-steps-item-active {
            .ant-steps-item-container {
              .ant-steps-item-tail {
                &:after {
                  background: $primary;
                }
              }

              .ant-steps-item-icon {
                border-color: $primary;

                &:before {
                  opacity: 1;
                }
              }

              .ant-steps-item-content {
                .ant-steps-item-title {
                  color: $primary;
                  font-weight: bold;
                }
              }
            }
          }

          &.ant-steps-item-finish {
            .ant-steps-item-container {
              .ant-steps-item-tail {
                &:after {
                  background-color: $primary;
                }
              }

              .ant-steps-item-icon {
                background: #22c55e;
                border-color: #22c55e;

                &:before {
                  display: none;
                }

                .ant-steps-icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  top: 0;
                  color: $white;
                  height: 32px;
                }
              }

              .ant-steps-item-content {
                .ant-steps-item-title {
                  display: block;
                }
              }
            }
          }
        }
      }

      .steps-content {
        border-radius: 15px;
        border: 1px solid $black-rgba-1;
        background: $white;
        padding: 1.5rem 3rem;
        box-sizing: border-box;
        width: calc(100% - 380px);
        max-height: 68vh;
        overflow-y: auto;
        height: fit-content;

        .service-prefix {
          padding: 10px 20px !important;

          span {
            font-size: 1.1rem;
            font-weight: 400;
            margin-inline-end: -10px;
          }
        }

        .row {
          height: fit-content;
        }

        @media screen and (max-width: 1192px) {
          max-height: 380px;
        }

        .steps-form-heading {
          margin-bottom: 1rem;

          h5 {
            color: $black;
            font-size: 26px;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .card-element-wrapper {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding-top: 1.5rem;
    
        .ant-form-item {
          margin-bottom: 0px;
        }
    
        .error {
          color: #ff4d4f;
        }
    
        .holder-name input, .holder-name input::placeholder {
          font-weight: 500;
          font-size: 16px;
          color: #111111;
        }
    
        .card-number {
          width: 100%;
          height: 60px;
    
          .InputContainer input {
            font-weight: 500;
            font-size: 16px !important;
            color: #111111;
            text-transform: capitalize;
          }
        }
    
        .card-number,
        .card-expiry,
        .card-cvv {
          background: #f3f6f9;
          padding: 19px 16px;
          border-radius: 70px;
        }
      }
    }
  }

  .add-service-list-row {
    margin-top: 90px;
    padding: 1rem 2rem;
    height: calc(100vh - 175px);
    overflow-y: auto;

    &.add-service-list-row-update {
      margin-top: 65px;
    }
  }

  .edit-service-list-row {
    padding: 1rem;

    &.add-service-list-row-update {
      margin-top: 65px;
    }
  }

  .common-card-row {
    min-height: auto;
    border-radius: 15px;
    border: 1px solid $black-rgba-1;
    background: $white;
    padding: 1rem;

    .steps-form-heading {
      h5 {
        margin-bottom: 1.5rem;
        color: $black;
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
      }
    }

    .service-custom-checkbox {
      .ant-checkbox-group {
        max-height: 170px;
        overflow-y: auto;

        .ant-row {
          margin-left: -15px;
          margin-right: -15px;

          .ant-col {
            padding: 0 15px;
          }
        }

        .ant-checkbox-wrapper {
          border-radius: 10px;
          background: $white;
          box-shadow: 0 14px 34px 0 rgba(0, 0, 0, 0.05);
          width: 100%;
          padding: 10px;
          margin-bottom: 1rem;
          position: relative;

          .ant-checkbox {
            position: absolute;
            right: 1rem;
            left: auto;
            margin: auto;

            .ant-checkbox-inner {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background: $black-rgba-1;
              border: none;

              &:after {
                opacity: 1;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                width: 5.714286px;
                height: 11.142857px;
                inset-inline-start: 30.5%;
              }
            }

            &:after {
              border-color: transparent;
            }

            &.ant-checkbox-checked {
              .ant-checkbox-inner {
                background: $theme-gradient;
              }
            }
          }

          .ant-checkbox+span {
            width: 100%;
            padding: 0;
          }
        }

        .service-img-div {
          img {
            width: 50px;
            height: 50px;
            border-radius: 8px;
          }
        }

        .service-details {
          color: $black;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          padding-left: 15px;
        }
      }
    }

    .amount-details-row {
      padding-top: 30px;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
          color: $black;
          font-size: 16px;
          font-weight: 700;
          line-height: normal;

          &.list-inline-item:not(:last-child) {
            color: $gray;
            font-weight: 400;
          }
        }

        &.total-amount-ul {
          border-top: 1px solid #d9d9d9;
          padding-top: 10px;
          margin-bottom: 30px;

          li {}
        }
      }

      .process-div {
        .ant-btn.common-btn {
          width: 235px;
          height: 70px;
        }

        .common-btn-dis {
          width: 235px;
          height: 70px;
        }
      }
    }
  }

  .steps-action {
    position: relative;

    &.steps-action-custom {
      width: 100%;
      height: 80px;
      position: fixed;
      bottom: 1rem;
      background: #f8f8f8;
      z-index: 2;
      margin: auto;

      ul {
        li {
          color: $black;

          &.list-inline-item:not(:last-child) {
            margin-right: 1.5rem;
          }

          a {
            color: $black;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
          }
        }
      }

      .ant-btn {
        &.skip-btn {
          width: 235px;
          height: 70px;
          border: 0;
          border-radius: 70px;
          background: $gray-5;
          color: $black;
          font-size: 16px;
          font-weight: 700;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &.modal-fluid-main {
    background: transparent;
    padding: 1.5rem 0;
    max-height: 92vh;
    height: fit-content;

    .container {
      .create-profile-heading {
        border-bottom: 0;
        margin: 0 0 30px;
        padding-bottom: 0;

        h1 {
          font-size: 30px;
        }

        p {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }

    .common-card-row {
      min-height: initial;
      border-radius: 0;
      border: 0;
      padding: 0 65px;

      .steps-form-heading {
        h5 {
          margin-bottom: 40px;
          color: $black;
          font-size: 30px;
          font-weight: 700;
          line-height: normal;
        }
      }

      .service-custom-checkbox {
        .ant-checkbox-group {
          .ant-row {
            margin-left: -15px;
            margin-right: -15px;

            .ant-col {
              padding: 0 15px;
            }
          }

          .ant-checkbox-wrapper {
            border-radius: 10px;
            background: $white;
            box-shadow: 0 14px 34px 0 rgba(0, 0, 0, 0.05);
            width: 100%;
            padding: 10px;
            margin-bottom: 1rem;
            position: relative;

            .ant-checkbox {
              position: absolute;
              right: 0.5rem;
              top: 1.5rem;
              left: auto;
              margin: auto;

              .ant-checkbox-inner {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: $black-rgba-1;
                border: none;

                &:after {
                  opacity: 1;
                  transform: rotate(45deg) scale(1) translate(-50%, -50%);
                  width: 5.714286px;
                  height: 11.142857px;
                  inset-inline-start: 30.5%;
                }
              }

              &:after {
                border-color: transparent;
              }

              &.ant-checkbox-checked {
                .ant-checkbox-inner {
                  background: $theme-gradient;
                }
              }
            }

            .ant-checkbox+span {
              width: 100%;
              padding: 0;
            }
          }

          .service-img-div {
            img {
              width: 50px;
              height: 50px;
              border-radius: 8px;
            }
          }

          .service-details {
            color: $black;
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
          }
        }

        .service-custom-checkbox {
          .ant-checkbox-group {
            .ant-row {
              margin-left: -15px;
              margin-right: -15px;

              .ant-col {
                padding: 0 15px;
              }
            }

            .ant-checkbox-wrapper {
              border-radius: 10px;
              background: $white;
              box-shadow: 0 14px 34px 0 rgba(0, 0, 0, 0.05);
              width: 100%;
              padding: 10px;
              margin-bottom: 30px;

              .ant-checkbox {
                position: absolute;
                right: 1rem;
                top: 18px;
                left: auto;
                margin: auto;

                .ant-checkbox-inner {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  background: $black-rgba-1;
                  border: none;

                  &:after {
                    opacity: 1;
                    transform: rotate(45deg) scale(1) translate(-50%, -50%);
                    width: 5.714286px;
                    height: 11.142857px;
                    inset-inline-start: 30.5%;
                  }
                }

                &:after {
                  border-color: transparent;
                }

                &.ant-checkbox-checked {
                  .ant-checkbox-inner {
                    background: $theme-gradient;
                  }
                }
              }

              .ant-checkbox+span {
                width: 100%;
                padding: 0;
              }
            }

            .service-img-div {
              img {
                width: 50px;
                height: 50px;
                border-radius: 8px;
              }
            }

            .service-details {
              color: $black;
              font-size: 16px;
              font-weight: 600;
              line-height: 20px;
              padding-left: 15px;
            }
          }
        }

        .amount-details-row {
          padding-top: 30px;

          ul {
            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
              color: $black;
              font-size: 16px;
              font-weight: 700;
              line-height: normal;

              &.list-inline-item:not(:last-child) {
                color: $gray;
                font-weight: 400;
              }
            }

            &.total-amount-ul {
              border-top: 1px solid #d9d9d9;
              padding-top: 10px;
              margin-bottom: 30px;

              li {}
            }
          }

          .process-div {
            .ant-btn.common-btn {
              width: 235px;
              height: 70px;

            }

            .common-btn-dis {
              width: 235px !important;
              height: 70px !important;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.disabledBtn {
  height: 70px;
  width: 235px;
  padding: 20px 40px;
  border-radius: 70px;
  background: #e7e8ea;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  font-size: 16px;
  font-weight: 700;
  pointer-events: none;
}

.common-btn-dis {
  height: 52px;
  width: 235px;
  padding: 20px 40px;
  border-radius: 70px;
  background: linear-gradient(135deg, #02e09e 0%, #66b6fa 100%);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  font-size: 16px;
  font-weight: 700;
}